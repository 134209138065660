<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <input type="hidden" :value="setLocation">
            <input type="hidden" :value="GetVal">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label for="Contacto">Nombre Sucursal</label>
                            <input  v-model="objClienteSucursal.Nombre" type="text" placeholder="Ingrese un Nombre" class="form-control" />
                            <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*' + errorvalidacion.Nombre[0]"/>
                        </div>

                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label for="Contacto">Teléfono</label>
                            <input v-model="objClienteSucursal.Telefono" type="text" placeholder="Ingrese un Telefono" class="form-control" />
                            <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*' + errorvalidacion.Telefono[0]"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="Contacto">Dirección</label>
                            <input v-model="objClienteSucursal.Direccion" type="text" placeholder="Ingrese una Direccion" class="form-control" />
                            <CValidation v-if="this.errorvalidacion.Direccion" :Mensaje="'*' + errorvalidacion.Direccion[0]"/>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label class="col-form-label">&nbsp;Ubicación&nbsp;</label>
                            <CMapa v-if="this.isReady" :pConfigMapa="ConfigMapa" :oLatLng="coordenadas" />
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import CLoader from "../../../../components/CLoader";
import CValidation from "../../../../components/CValidation";
import CMapa from "@/components/CMapa.vue";

export default {
    name: "FormClientesSucursal",
    props: ['poBtnSave'],
    components: {
        CLoader,
        CValidation,
        CMapa
    },
    data() {
        return {
            Emit:               this.poBtnSave.EmitSeccion,
            errorvalidacion:    [],
            blockText: false,
            isReady: false,
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objClienteSucursal:{
                IdClienteSucursal: 0,
                IdCliente: 0,
                Nombre:  '',
                Telefono: '',
                Direccion: '',
                Latitud: 0,
                Longitud: 0
            },

            ConfigMapa:{
                ShowBuscador: true
            },
            coordenadas: {
                Lat: 20.9673271,
                Lng: -89.6249853,
            },

        }
    },
    methods: {
        async Guardar() {
            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0;
            this.poBtnSave.DisableBtn = true;
            this.$http.post('sucursalcliente',this.objClienteSucursal).then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res) {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast      = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err) {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },

        Recuperar()
        {
            this.$http.get("sucursalcliente/"+this.objClienteSucursal.IdClienteSucursal)
                .then( (res) => {
                    this.objClienteSucursal = res.data.data;
                    this.setDefaultorCurrentLocation();
                    this.isReady = true;

                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;

                });
        },

        Limpiar() {
            this.objClienteSucursal = {
                IdClienteSucursal: 0,
                IdCliente: 0,
                Nombre:  '',
                Telefono: '',
                Direccion: '',
                Latitud: 0,
                Longitud: 0
            },
            this.errorvalidacion = [];
        },

        setDefaultorCurrentLocation() {
            this.coordenadas.Lat = (this.objClienteSucursal.Latitud != null || this.objClienteSucursal.Latitud != undefined) ? parseFloat(this.objClienteSucursal.Latitud) : 20.9673271;
            this.coordenadas.Lng = (this.objClienteSucursal.Longitud != null || this.objClienteSucursal.Longitud != undefined) ? parseFloat(this.objClienteSucursal.Longitud) : -89.6249853;
        },

    },
    created() {


        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$off('Save_'+this.Emit);
        this.ConfigLoad.ShowLoader = true;

    },
    mounted() {

        this.bus.$on('Recovery_'+this.Emit,(id)=> {


            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });

            this.Limpiar();

            if (id != undefined && id !== '') {
                this.objClienteSucursal.IdClienteSucursal = id;
                this.Recuperar();

            }else {
                this.isReady = true;
                this.ConfigLoad.ShowLoader = false;
            }


        });
    },
    computed :{
        setLocation: function () {
            this.objClienteSucursal.Longitud = this.coordenadas.Lng;
            this.objClienteSucursal.Latitud  = this.coordenadas.Lat;
            return this.objClienteSucursal;
        },

        GetVal() {
            if (this.poBtnSave.IdObjeto != undefined && parseInt(this.poBtnSave.IdObjeto) > 0) {
                this.objClienteSucursal.IdCliente = this.poBtnSave.IdObjeto;
            }
        }
    }
}
</script>


