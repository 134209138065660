<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label for="Nombre">Nombre del Negocios</label>
                            <input type="text" v-model="objNegocio.Negocio" maxlength="250" class="form-control" placeholder="Ingresar Nombre" />
                            <CValidation v-if="this.errorvalidacion.Negocio" :Mensaje="'*'+errorvalidacion.Negocio[0]"/>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

    export default {
        name:  "FormNegocios",
        props: ['poBtnSave'],
        data() {
            return {
                Emit:               this.poBtnSave.EmitSeccion,
                errorvalidacion:    [],
                ListaSucursalesArr: [],
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                objNegocio:{
                    IdTipoNegocio: 0,
                    Negocio:    '',
                },
            }
        },
        methods: {
            async Guardar() {
                this.errorvalidacion      = [];
                this.poBtnSave.toast      = 0;
                this.poBtnSave.DisableBtn = true;

                if(this.objNegocio.IdTipoNegocio == 0) {
                    this.$http.post('negocios', this.objNegocio).then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
                } else {
                    this.$http.put('negocios/'+this.objNegocio.IdTipoNegocio,this.objNegocio).then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
                }
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }
            },
            Recuperar() {
                this.$http.get("negocios/"+this.objNegocio.IdTipoNegocio).then( (res) => {
                    this.objNegocio = res.data.data;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Limpiar() {
                this.objNegocio = {
                    IdTipoNegocio:    0,
                    Negocio: ''
                };
                this.errorvalidacion = [];
            },
        },
        created() {
            this.poBtnSave.toast = 0;

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(Id)=> {
                this.ConfigLoad.ShowLoader = true;
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,()=> {
                    this.Guardar();
                });
                this.Limpiar();

                if(Id!='') {
                    this.objNegocio.IdTipoNegocio = Id;
                    this.Recuperar();
                } else {
                    this.ConfigLoad.ShowLoader = false;
                }

            });
        },
    }

</script>
