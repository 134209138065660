import ViewReasignacion from "@/views/modulos/crm/reasignacion/ViewReasignacion.vue";

const VarRutasCrm = [
    {
        path: "/reasignacionclientes",
        name: "reasignacionclientes",
        component: ViewReasignacion,
        props: true,
    },
];

export default {
    VarRutasCrm,
}
