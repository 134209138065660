<template>
    <div>
    
        <NavLinks></NavLinks>

        <section class="mt-2">
            <div class="container-fluid">
                <transition name="fade" mode="out-in">
                    <router-view :key="$route.fullPath"/>
                </transition>
            </div>
        </section>

    </div>
</template>

<script>

    import NavLinks from '@/views/template/NavLinks.vue';

    export default {
        name:  'Template',
        props: [""],
        components:{
            NavLinks
        },
        data() {
            return {
                SessionStorage: {},
            }
        },
        methods: {
            Getdatauser() { // DATOS DE SESSIÓN 
                this.SessionStorage = JSON.parse(sessionStorage.getItem('user'));
            },
        },
        created() {
            this.Getdatauser();
        },
    }
    
</script>
