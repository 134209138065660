<template>
    <div>
        <CList :pConfigList="ConfigList" :segurity="segurity">
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div class="form-group row">
                            <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                                <label>Sucursal</label>
                                <select id="IdSucursal" v-model="IdSucursal" @change="ListaPerfil();" v-show="ShowCmbSucursales" class="form-control form-select mr-2">
                                    <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                        {{ item.Nombre }}
                                    </option>
                                </select>
                            </div>


                            <div class="col-12 col-sm-12 col-md-5 col-lg-5 text-right">

                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <label for="item19">Vendedor</label>
                                <select v-model="IdRuta" class="form-control form-select" @change="setTipoTransferencia();">
                                    <option value="0">--Seleccionar--</option>
                                    <option v-for="(item, index) in listEntregadores" :key="index" :value="item.IdRuta">
                                        {{item.NombreCompleto}} |  &laquo; {{item.NombrePuesto}} &raquo;
                                    </option>
                                </select>
                                <CValidation v-if="this.errorvalidacion.IdRuta1" :Mensaje="'*' + errorvalidacion.IdRuta1[0]"/>
                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                                <button type="button" @click="Transferir();" class="btn btn-sm btn-primary mt-4">
                                    <i class="fa fa-send"></i> Transferir Todos
                                </button>
                            </div>

                        </div>

                        <br>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="form-row">
                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <h3>Clientes</h3>
                                        <draggable  id="div2" class="droppable" :list="listClientesRuta" group="rutas" >
                                            <div class="list-group-item" v-for="(element, index) in listClientesRuta" :key="element.NombreCompleto">
                                                <div class="row">
                                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                                        {{ index+1 }}
                                                    </div>
                                                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                        <b>{{ element.Folio }}</b>
                                                    </div>
                                                    <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 uppercase">
                                                        {{$limitCharacters(element.NombreCompleto,24)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </draggable>
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <h3>Cliente asignados al vendedor</h3>
                                        <draggable  id="div1" class="droppable bg-feed" :list="listClientesAsig" group="rutas">
                                            <div class="list-group-item" v-for="(element, index) in listClientesAsig" :key="element.NombreCompleto">
                                                <div class="row">
                                                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                                        {{ index+1 }}
                                                    </div>
                                                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                        <b>{{ element.NumCliente }}</b>
                                                    </div>
                                                    <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 uppercase">
                                                        {{$limitCharacters(element.NombreCompleto,24)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </draggable>
                                    </div>
                                </div>
                            </div><!--fin col-12-->
                        </div>
                        <!-- <pre>{{listEntregadores }}</pre> -->

                        <div class="row mt-2">
                            <div class="col-12 text-right">
                                <CBtnSave v-if="segurity.BtnSave" :poBtnSave="oBtnSave" />
                            </div>
                        </div>
                    </template>
                </CLoader>
            </template>
        </CList>
    </div>
</template>

<script>

import Configs from "@/helpers/VarConfig";
import CList from "@/components/CList.vue";
import CLoader from "@/components/CLoader.vue";
import CBtnSave from "@/components/CBtnSave.vue";
import CValidation  from '@/components/CValidation.vue'
const  EmitEjecuta  = "seccionReasignacion";

export default {
    name: "ListaClientes",
    components: {
        CList,
        CLoader,
        CBtnSave,
        CValidation
    },
    data() {
        return {
            ConfigList: {
                Title:          'Reasignación',
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  false,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'Form',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                TitleFirst:     'Menú',
            },
            Filtro: {
                Nombre:      "",
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: "Buscar..",
            },
            oBtnSave: {
                toast:          0,
                IsModal:        false,
                ShowBtnSave:    true,
                ShowBtnCancel:  false,
                EmitSeccion:    EmitEjecuta,
            },
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            IdRuta: 0,
            IdRuta2: 0,
            Asignar: 0,
            listEntregadores:   [],
            listGestoresRuta:   [],
            listOtrasRutas:     [],
            listClientesRuta:   [],
            listClientesAsig:   [],
            errorvalidacion:    [],
            segurity:           {},
            ListaSucursalesArray: [],
            IdSucursal: 0,
            ShowCmbSucursales: false,
            TipoCambio: 0,
        };
    },
    methods: {
        Guardar()
        {
            this.errorvalidacion     = [];
            this.oBtnSave.toast      = 0;
            //this.oBtnSave.DisableBtn = true;

            let UsuarioRuta = this.ObtenerGestor(this.IdRuta);
            let UsuarioDestino = this.ObtenerGestor(this.IdRuta2);

            let newRequest = {
                TipoCambio: this.TipoCambio,
                IdRuta1:    this.IdRuta,
                UsuarioOrg: UsuarioRuta,
                Asignar:    this.Asignar,
                IdRuta2:    this.IdRuta2,
                UsuarioDes: UsuarioDestino,
                Pendientes: this.listClientesRuta,
                Asignados:  this.listClientesAsig,
            }

            this.$http.post('asignacionMasiva',newRequest
            ).then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res) {
            this.oBtnSave.DisableBtn = false;
            this.oBtnSave.toast = 1;
            this.TipoCambio = 0;
            this.ListaPerfil();
            //this.bus.$emit('CloseModal_'+this.EmitSeccion);
            //this.bus.$emit('List_'+this.EmitSeccion);
        },
        EjecutaConError(err) {
            this.oBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast  = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        ListaSucursales()
        {
            this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
                .then((res) => {
                    let response = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.admin == 0)
                    {
                        this.IdSucursal = response.IdSucursal;
                    }
                    else
                    {
                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0)
                        {
                            this.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }
                }).finally(()=>{
                this.ListaPerfil();
            });
        },
        async ListaPerfil()
        {
            this.ConfigLoad.ShowLoader = true;
            this.IdRuta = 0;
            this.IdRuta2 = 0;
            this.Asignar = 0;
            this.listClientesRuta = [];
            this.listClientesAsig = [];
            this.listOtrasRutas = [];

            await this.$http.get("getUsuariosReasignacion", {
                params: {
                    IdSucursal: this.IdSucursal,
                },
            })
                .then((res) => {
                    this.listGestoresRuta = [];
                    this.listEntregadores = res.data.data;
                    this.segurity         = res.data.segurity;
                }).finally((res) => {
                    this.ConfigLoad.ShowLoader = false;
                });
        },
        setTipoTransferencia()
        {

            this.getListaPrestamos();


            this.$http.get("getUsuariosMismaRuta", {
                params: {
                    IdRuta: this.IdRuta,
                },
            })
                .then((res) => {
                    this.listGestoresRuta = res.data.data;
                });

            let cont = this.IdRuta
            let elemento = this.listEntregadores.filter(function(item,index){
                if(cont != item.IdRuta){
                    return item;
                }
                else{
                    return '';
                }
            });
            this.listOtrasRutas = elemento;
        },

        getListaPrestamos()
        {
            this.ConfigLoad.ShowLoader = true;
            this.listClientesAsig = [];
            if(this.IdRuta == 0){
                this.listClientesRuta = [];
                this.ConfigLoad.ShowLoader = false;
                return false;
            }
            this.$http.get("getPrestamosAsignados", {
                params: {
                    IdRuta: this.IdRuta,
                    EstatusPrestamo: 'Cobranza',
                    Estatus: 'Activo',
                },
            })
                .then((res) => {
                    this.listClientesRuta = res.data.data;
                }).finally((res) => {
                this.ConfigLoad.ShowLoader = false;
            });
        },
        ObtenerGestor(Id)
        {
            let elemento = this.listEntregadores.filter(function(item,index){
                if(Id == item.IdRuta){
                    return item;
                }
                else{
                    return '';
                }
            });

            if(elemento[0]){
                return elemento[0].IdUsuario;
            }
        },
        Transferir()
        {
            if(this.listClientesRuta.length>0)
            {
                this.listClientesRuta.forEach((value,index)=>{

                    let element = this.listClientesAsig.filter(function(item,index){
                        if(item.IdPrestamo == value.IdPrestamo){
                            return item;
                        }
                        else{
                            return '';
                        }
                    });

                    if(!element[0]){
                        this.listClientesAsig.push(value);
                    }
                });

                this.listClientesRuta = [];
            }
            else if(this.listClientesRuta.length == 0 && this.listClientesAsig.length>0)
            {
                this.listClientesAsig.forEach((value,index)=>{

                    let element = this.listClientesRuta.filter(function(item,index){
                        if(item.IdPrestamo == value.IdPrestamo){
                            return item;
                        }
                        else{
                            return '';
                        }
                    });

                    if(!element[0]){
                        this.listClientesRuta.push(value);
                    }
                });

                this.listClientesAsig = [];
            }
        }
    },
    created() {
        this.ListaSucursales();
        this.bus.$off('Save_'+this.ConfigList.EmitSeccion);
    },
    mounted() {
        this.bus.$on('Save_'+this.ConfigList.EmitSeccion,()=> {
            this.Guardar();
        });
    },
};
</script>
<style scoped>
.mxinp{
    transform: scale(1.3);
}
</style>
