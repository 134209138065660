<template>
    <small class="text-danger">{{Mensaje}}</small>
</template>

<script>

export default {
    name:  "CValidation",
    props: ["Mensaje"],
}
</script>