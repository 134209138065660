<template>
    <div>
        <div class="row">
            <div class="col-12">
                <table class="table table-responsive-lg">
                    <thead>
                    <tr>
                        <th>Part.</th>
                        <th>Descripcion</th>
                        <th>Cantidad</th>
                        <th>Precio Unitario</th>
                        <th>Importe</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in listaDetalle" :key="index" >
                        <td>{{ index+1 }}</td>
                        <td>{{ item.Descripcion }}</td>
                        <td>{{ item.Cantidad }}</td>
                        <td>{{ $formatNumber(item.PrecioUnitario,'$') }}</td>
                        <td>{{ $formatNumber(item.Importe,'$') }}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "viewDetallePedido",
    props: ['poBtnSave'],
    components: {},

    data() {
        return {
            Emit: this.poBtnSave.EmitSeccion,
            listaDetalle: []
        }
    },
    methods: {},
    computed: {},
    created() {
        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            this.listaDetalle = obj

        });
    }

}
</script>
