<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <div class="form-group row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <label for="Nombre">Nombre</label>
                                            <input type="text" v-model="objProveedor.Nombre"   maxlength="250" class="form-control" placeholder="Ingresar Nombre" />
                                            <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <label for="">Dirección</label>
                                            <input type="text" v-model="objProveedor.Direccion"   maxlength="250" class="form-control" placeholder="Ingresar una direccion" />
                                            <CValidation v-if="this.errorvalidacion.Direccion" :Mensaje="'*'+errorvalidacion.Direccion[0]" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

import CValidation from "@/components/CValidation.vue";
import $$             from "jquery"
import Configs        from '@/helpers/VarConfig.js';
import StaticComboBox from '@/helpers/StaticComboBox.js';
import moment from "moment";

export default {
    name:  "FormProveedores",
    props: ['poBtnSave'],
    components:{
        CValidation//CBtnSave
    },
    data() {
        return {
            RutaFile:        '',
            Img:             null,
            Emit:            this.poBtnSave.EmitSeccion,
            ValidElement:    Configs.validImage2m,
            TipoEquipo:      StaticComboBox.Equipo,
            errorvalidacion: [],
            ConfigLoad: {
                ShowLoader: true,
                ClassLoad: false,
            },
            objProveedor: {
                IdProveedor:        0,
                Nombre: '',
                Direccion: ''
            },

            ListaSucursalesArray: [],
            masks: {
                input: "YYYY-MM-DD",
            },
            tipoFormulario:0,
        }
    },
    methods :
        {
            async Guardar()
            {
                this.errorvalidacion=[];
                this.poBtnSave.toast = 0;
                this.poBtnSave.DisableBtn = true;

                let formData = new FormData();
                formData.set('IdProveedor',  this.objProveedor.IdProveedor);
                formData.set('Nombre',       this.objProveedor.Nombre);
                formData.set('Direccion',    this.objProveedor.Direccion);



                /*let picture = this.$refs.file.files[0];
                formData.append('Imagen',picture);*/

                this.$http.post('proveedores', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    this.EjecutaConExito(res);
                }).catch(err=>{
                    this.EjecutaConError(err);
                });
            },

            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },

            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                }
                else{
                    this.$toast.error(err.response.data.message);
                }
            },

            Recuperar() {
                this.$http.get("proveedores/"+this.objProveedor.IdProveedor
                ).then( (res) => {
                    this.objProveedor = res.data.data;
                    //this.RutaFile   = res.data.rutaFile;

                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },

            Limpiar() {
                this.objProveedor = {
                    IdProveedor:        0,
                    Nombre: '',
                    Direccion: ''

                };
                this.errorvalidacion = [];
            },

        },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id) => {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);

            this.bus.$on('Save_'+this.Emit,() => {
                this.Guardar();
            });

            this.Limpiar();

            if(Id!='') {
                this.objProveedor.IdProveedor = Id;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }

        });
    },

    computed:{

    }
}

</script>
