<template>
    <div>
        
        <div class="row mt-2">
            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="row">
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <h3>Productividad General del Día</h3>
                                    </div>
                                    <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <form onsubmit="return false" class="form-inline float-right">
                                            <div class="form-group">

                                                <!-- <select id="IdSucursal" v-model="IdSucursalProd" @change="tblProductosReporte();" class="form-control form-select mr-1">
                                                    <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                                        {{ item.Nombre }}
                                                    </option>
                                                </select>

                                                <div class="form-group mx-sm-2">
                                                    <v-date-picker v-model="objFiltroFechas" @input="tblProductosReporte()" :masks="masks" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1] }' locale="es" :max-date="stopDate()">
                                                        <template v-slot="{ inputValue, inputEvents }">
                                                            <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                                        </template>
                                                    </v-date-picker>
                                                </div> -->

                                                <button type="button" @click="tblProductosReporte()" v-b-tooltip.hover.Top title="Recargar" class="btn btn-primary btn-sm mr-2">
                                                    <i class="far fa-redo"></i>
                                                </button>


                                            </div>
                                        </form>

                                    </div>

                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <CLoader :pConfigLoad="ConfigLoadProdTbl">
                                            <template slot="BodyFormLoad">
                                                <table class="table table-responsive-lg">
                                                    <thead>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th>Cant. Requerida</th>
                                                    
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(item,index) in listaproductos" :key="index">
                                                        <td>{{item.Descripcion}}</td>
                                                        <td>{{item.CantidadRequerida}}</td>
                                                    </tr>

                                                    <CSinRegistros :pContIF="listaproductos.length" :pColspan="7"></CSinRegistros>
                                                    </tbody>
                                                </table>
                                            </template>
                                        </CLoader>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import CSinRegistros from "../../../components/CSinRegistros";
import moment from "moment";

export default {
    name:  "DashboardProduccion",
    props: [],
    components: {
        CSinRegistros,
    },
    data() {
        return {
            objFiltroFechas: new Date(),


            ListaSucursalesArray:[],
            listaproductos:[],
            showTblProd:false,
            ConfigLoadProdTbl:{
                ShowLoader: true,
                ClassLoad:  true,
            }
        };
    },
    methods: {
        async tblProductosReporte() {
            this.showTblProd        = false;
            this.ConfigLoadProdTbl.ShowLoader = true;
            this.listaproductos = [];
            //this.FiltroFecha = moment(this.objFiltroFechas).format('YYYY-MM-DD');

            await this.$http.get("dashproduccion", {
                params: {
                    IdSucursal: this.IdSucursalProd,
                    Fecha: this.FiltroFecha
                },
            }).then((res) => {
                
                const response = res.data.data.productividadRuta;
                this.listaproductos = response;


            }).finally(() => {
                this.ConfigLoadProdTbl.ShowLoader = false;
            });
        },


    },

    created() {
       
    },
};

</script>
