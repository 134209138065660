<template>
    <div class="form-group">
        <label>Sucursal</label>
        <input :value="checkId" type="hidden">
        <input :value="validate" type="hidden">
        <v-select
                :value="localValue"
                @input="$emit('input', $event)"
                @search="onSearchClientes"
                :filterable="false"
                :reduce="item => item.key"
                :options="clientesSucursales"
                :disabled="configInput.disabled"
                placeholder="Buscar cliente">

            <template slot="no-options">
                No hay Sucursales
            </template>

            <template slot="option" slot-scope="option">
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-1 col-lg-2 col-xl-1">
                        <i class="fa fa-user" ></i>
                    </div>
                    <div class="col-10 col-sm-10 col-md-11 col-lg-10 col-xl-8">
                        {{ option.label }}
                        <div class="row">
                            <div class="col-12"  >
                                {{ option.detalle.Direccion }}
                            </div>
                        </div>
                    </div>

                </div>
            </template>

        </v-select>

        <slot></slot>

    </div>
</template>

<script>
export default {

    props: ['value','pIdCliente','pDisabled'],

    data() {
        return {
            localValue: 0,
            localIdCliente:0,
            //form: this.value,

            clientesSucursales: [],
            debounce: null,
            configInput:{
                disabled: false,
            }


        }
    },

    methods: {

        onSearchClientes( search, loading ) {

            this.clientesSucursales = [];

            clearTimeout( this.debounce );

            loading(true);

            this.debounce = setTimeout( async () => {

                await this.getClientes( search, loading );

            }, 1000);

        },

        async getClientes( search, loading ) {

            const res = await this.$http.get( '/sucursalcliente', {
                params: {
                    TxtBusqueda: search,
                    items: 10,
                    page: 1,
                    IdCliente: this.localIdCliente
                }
            });

            loading(false);

            if ( res.status != 200 ) {

                this.clientesSucursales = [];

            };


            this.clientesSucursales = res.data.data.data.map( (item) => ({
                label: `${item.Nombre}`,
                detalle: {
                    contacto: item.Direccion,
                },
                key: item.IdClienteSucursal
            }) );

        },

    },
    computed:{
        checkId: function (){
            this.localValue = parseInt(this.value);

        },

        validate: function () {
            if(this.pIdCliente !== undefined) {
                this.localIdCliente = parseInt(this.pIdCliente);
            }

            if(this.pDisabled != undefined){
                this.configInput.disabled = this.pDisabled
            }
        }
    }

}
</script>

<style>

</style>
