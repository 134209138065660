<template>
	<CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
			<form id="FormProveedorFacturacion" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="IdEmpresa">IdEmpresa</label>
									<input type="text" v-model="objProveedorFacturacion.IdEmpresa" class="form-control" id="IdEmpresa" placeholder="Ingresar IdEmpresa" />
									<CValidation v-if="this.errorvalidacion.IdEmpresa" :Mensaje="'*'+errorvalidacion.IdEmpresa[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Version">Version</label>
									<input type="text" v-model="objProveedorFacturacion.Version" class="form-control" id="Version" placeholder="Ingresar Version" />
									<CValidation v-if="this.errorvalidacion.Version" :Mensaje="'*'+errorvalidacion.Version[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="BaseUrl">BaseUrl</label>
									<input type="text" v-model="objProveedorFacturacion.BaseUrl" class="form-control" id="BaseUrl" placeholder="Ingresar BaseUrl" />
									<CValidation v-if="this.errorvalidacion.BaseUrl" :Mensaje="'*'+errorvalidacion.BaseUrl[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Usuario">Usuario</label>
									<input type="text" v-model="objProveedorFacturacion.Usuario" class="form-control" id="Usuario" placeholder="Ingresar Usuario" />
									<CValidation v-if="this.errorvalidacion.Usuario" :Mensaje="'*'+errorvalidacion.Usuario[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Password">Password</label>
									<input type="text" v-model="objProveedorFacturacion.Password" class="form-control" id="Password" placeholder="Ingresar Password" />
									<CValidation v-if="this.errorvalidacion.Password" :Mensaje="'*'+errorvalidacion.Password[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="NumTimbres">NumTimbres</label>
									<input type="text" v-model="objProveedorFacturacion.NumTimbres" class="form-control" id="NumTimbres" placeholder="Ingresar NumTimbres" />
									<CValidation v-if="this.errorvalidacion.NumTimbres" :Mensaje="'*'+errorvalidacion.NumTimbres[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Estatus">Estatus</label>
									<input type="text" v-model="objProveedorFacturacion.Estatus" class="form-control" id="Estatus" placeholder="Ingresar Estatus" />
									<CValidation v-if="this.errorvalidacion.Estatus" :Mensaje="'*'+errorvalidacion.Estatus[0]"/>
								</div>
							</div>

							<br>
						</div><!--fin col-12-->
					</div>
				</div>
			</form>
		</template>
	</CLoader>
</template>

<script>
import CValidation from "@/components/CValidation.vue";
export default {
    name: 'FormProveedorFacturacion',
    props: ['poBtnSave'],
    components:{
        CValidation
    },
    data() {
        return {
			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objProveedorFacturacion: {
				IdProveedorFacturacion: 0,
				IdEmpresa: 0,
				Version: '',
				BaseUrl: '',
				Usuario: '',
				Password: '',
				NumTimbres: '',
				Estatus: '',

            },
            errorvalidacion: [],
			Emit: this.poBtnSave.EmitSeccion,
			masks:{ input: 'YYYY-MM-DD'}, // quitar sino lleva fecha
        }
    },
    methods :
    {
       	async Guardar()
        {
			this.errorvalidacion = [];
            this.poBtnSave.toast = 0;
            this.poBtnSave.DisableBtn = true;

			if(this.objProveedorFacturacion.IdProveedorFacturacion == 0) {
				await this.$http.post(
					'ProveedoresFacturacion',this.objProveedorFacturacion
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'ProveedoresFacturacion/'+this.objProveedorFacturacion.IdProveedorFacturacion,
					this.objProveedorFacturacion
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
		Recuperar()
        {
            this.$http.get(
                "ProveedoresFacturacion/"+this.objProveedorFacturacion.IdProveedorFacturacion
            ).then((res) => {
                this.objProveedorFacturacion = res.data.data;

				// en caso de que necesite fecha
				/*let formatedDate1 = this.objProveedorFacturacion.Fecha.replace(/-/g,'\/');
                this.objProveedorFacturacion.Fecha = new Date(formatedDate1);*/
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objProveedorFacturacion = {
				IdProveedorFacturacion: 0,
				IdEmpresa: 0,
				Version: '',
				BaseUrl: '',
				Usuario: '',
				Password: '',
				NumTimbres: '',
				Estatus: '',

            }
            this.errorvalidacion = [];
        },
    },
    created() {
		this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();

            if (Id!='')
            {
                this.objProveedorFacturacion.IdProveedorFacturacion = Id;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    }
}
</script>
