<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="Filtros">
                <label  class="mr-1">Estatus</label>
                <select v-model="Filtro.EstatusPedido" class="form-control form-select mr-2" @change="Lista">
                    <option value="">Todos</option>
                    <option value="Pendiente">Pendientes</option>
                    <option value="Aceptado">Aceptados</option>
                    <option value="Produccion">En Produccion</option>
                    <option value="EnTransito">En Transito</option>
                    <option value="Entregado">Entregados</option>
                    <option value="Cancelado">Cancelados</option>
                </select>
            </template>

            <template slot="header">
                <th class="td-sm"></th>
                <th>Folio</th>
                <th>F. Levantamiento</th>
                <th>Cliente</th>
                <th>Sucursal</th>
                <th class="text-right">Total</th>
                <th>Estatus</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista, index) in ListaArrayRows" :key="index">
                    <td class="td-sm"></td>
                    <td><b>{{ lista.FolioPedido }}</b></td>
                    <td>{{ $getCleanDate(lista.FechaLevantamiento,false) }}</td>

                    <td  v-if="lista.cliente !== null" >{{ $limitCharacters(lista.cliente.NombreComercial,45) }}</td>
                    <td v-else>-----</td>

                    <td v-if="lista.clientesucursal !== null">{{ $limitCharacters(lista.clientesucursal.Nombre,45) }}</td>
                    <td v-else>-----</td>

                    <td class="text-right">{{ $formatNumber(lista.Total,'$') }}</td>

                    <td>
                        <b-badge v-if="lista.Estatus == 'Pendiente'" pill variant="primary">{{ lista.Estatus }}</b-badge>
                        <b-badge v-if="lista.Estatus == 'PreAutorizado'" pill variant="warning">{{ lista.Estatus }}</b-badge>
                        <b-badge v-if="lista.Estatus == 'Aceptado'" pill variant="info">{{ lista.Estatus }}</b-badge>
                        <b-badge v-if="lista.Estatus == 'Produccion'" pill variant="warning">{{ lista.Estatus }}</b-badge>
                        <b-badge v-if="lista.Estatus == 'EnTransito'" pill variant="info">{{ lista.Estatus }}</b-badge>
                        <b-badge v-if="lista.Estatus == 'Entregado'" pill variant="success">Entregado</b-badge>
                        <b-badge v-if="lista.Estatus == 'Cancelado'" pill variant="danger">{{ lista.Estatus }}</b-badge>
                    </td>
                    

                    <td class="text-center">
                        <CBtnAccion :pGoRoute="ConfigList.GoRoute" :pShowBtnEdit="true" :pShowBtnDelete="lista.Estatus == 'Pendiente'" :pIsModal="false" :pId="lista.IdPedido" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                            <template slot="btnaccion">

                                <template v-if="segurity.PDFOrderProduccion">
                                    <!--<button type="button" @click="getPdfOrdenProduccion(lista);" class="btn btn-info btn-icon" v-b-tooltip.hover.Top title="Ver Orden de Produccion">
                                        <i class="fa fa-file-pdf"></i>
                                    </button>-->
                                </template>

                            </template>
                        </CBtnAccion>
                    </td>
                </tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="8" />
            </template>
        </CList>

    </div>
</template>
<script>
import Configs    	    from "@/helpers/VarConfig.js";
import CSinRegistros    from "@/components/CSinRegistros.vue";
import CList            from "@/components/CList.vue";
import CBtnAccion       from "@/components/CBtnAccion.vue";
const EmitEjecuta 	    = "seccionPedidosNotasVenta";

export default {
    name: "ListPedidos",
    props: ['sFiltros'],
    components: {
        CSinRegistros,
        CList,
        CBtnAccion
    },

    data() {
        return {
            ListaArrayRows: [],
            segurity: 		{ Add:1,Edit:1,Delete:1,PDFOrderProduccion:1},
            ConfigList: {
                Title: 			"Pedidos",
                IsModal: 		false,
                ShowLoader: 	true,
                BtnReturnShow:  false,
                EmitSeccion: 	EmitEjecuta,
                GoRoute: 		"formulariopedidos",
                TitleFirst:     'Inicio',
                BtnNewShow:     true,
            },
            
            Filtro: {
                Nombre: 	 "",
                Pagina: 	 1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: "Folio",
                EstatusPedido: "",
            },
        }
    },
    methods: {
        async Lista() {
            this.ConfigList.ShowLoader = true;

            await this.$http.get("pedidos", {
                params: {
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada: 	 this.Filtro.Entrada,
                    Pag: 		 this.Filtro.Pagina,
                    Estatus:     this.Filtro.EstatusPedido
                },
            })
                .then((res) => {
                    this.Filtro.Pagina    = res.data.data.current_page;
                    this.Filtro.TotalItem = res.data.data.total;
                    this.ListaArrayRows   = res.data.data.data;
                    //this.segurity         = res.data.segurity;
                })
                .finally(() => {
                    this.ConfigList.ShowLoader = false;
                    this.$saveFilters('SET');
                });
        },

        Eliminar(Id){
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value) {
                    this.$http.delete('pedidos/'+Id).then( (res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                }
            });
        },

        getPdfOrdenProduccion(lista){
            /*let urlPdf = `${this.$http.defaults.baseURL}/getpdfordenproduccion/${lista.IdOrdenProduccion}`;
            window.open(urlPdf,'_blank');*/
        },

    },

    created() {
        if(this.sFiltros===undefined) {
            this.$saveFilters('GET');
        }

        this.bus.$off("Delete_" + EmitEjecuta);
        this.bus.$off("List_" + EmitEjecuta);

        sessionStorage.setItem('IdGeneral','0');
        sessionStorage.setItem('IdSubGeneral','0');
    },
    mounted() {
        this.Lista();
        this.bus.$on("Delete_" + EmitEjecuta, (Id) => {
            this.Eliminar(Id);
        });

        this.bus.$on("List_" + EmitEjecuta, () => {
            this.Lista();
        });
    },
    computed: {},

}
</script>
