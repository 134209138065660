<template>
    <div>
        <CList :pConfigList="ConfigList" >
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Orden de Recolección
                                </a>
                            </li>
                            <!--<li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false" >
                                    Distribución
                                </a>
                            </li>-->
                        </ul>

                        <div class="tab-content shadow-sm" id="myTabContent">
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Proveedor&nbsp;</legend>
                                                <div class="form-row mt-1">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Folio Orden </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objRecoleccion.FolioRecoleccion" type="text" placeholder="" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row ">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <BuscadorProveedores ref="buscadorProveedor" @input="getProveedor()" v-model="objRecoleccion.IdProveedor">
                                                                    <CValidation v-if="this.errorvalidacion.IdProveedor" :Mensaje="'*' + errorvalidacion.IdCliente[0]"/>
                                                                </BuscadorProveedores>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Dirección </label>
                                                                <input class="form-control" id="RazonSocial" v-model="objProveedor.Direccion" type="text" placeholder="" disabled/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row ">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">


                                                    </div>
                                                </div>


                                            </fieldset>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;General de la Orden &nbsp;</legend>
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <label for="Contacto">Fecha Recolección </label>
                                                                        <v-date-picker :masks="masks" :popover="{ visibility: 'focus'}" locale="es" v-model="objRecoleccion.Fecha" :minDate="new Date()">
                                                                            <template v-slot="{ inputValue, inputEvents }">
                                                                                <input class="form-control cal" name="Fecha[]" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly/>
                                                                            </template>
                                                                        </v-date-picker>
                                                                        <CValidation v-if="this.errorvalidacion.Fecha" :Mensaje="'*'+errorvalidacion.Fecha[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <label for="Observaciones">Observaciones </label>
                                                                        <textarea id="Observaciones" placeholder="Ingrese Observaciones..." rows="1" wrap="soft" class="form-control" v-model="objRecoleccion.Observaciones"></textarea>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-12 mt-1">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Detalle &nbsp;</legend>
                                                <div class="row">
                                                    <div class="col-md-12 jutify-content-end">
                                                        <button type="button" @click="agregarDetalle()" class="btn btn-primary btn-sm float-right mt-1 mb-1">
                                                            <i class="fas fa-plus-circle"></i> Agregar
                                                        </button>
                                                    </div>
                                                </div>
                                                <CTablita :pConfigList="ConfigListDetalle">
                                                    <template slot="header">
                                                        <th>Part.</th>
                                                        <th>Descripcion</th>
                                                        <th>Cantidad</th>
                                                        <th>Precio Unitario</th>
                                                        <th>Importe</th>
                                                        <th class="text-center">Acciones</th>

                                                    </template>
                                                    <template slot="body">
                                                        <tr  v-for="(item,index) in objDetalleRecoleccion"  :key="index">

                                                            <td>{{ index+1 }}</td>
                                                            <td>
                                                                <textarea
                                                                    v-model="item.Descripcion"
                                                                    class="form-control form-control-sm"
                                                                    rows="2" ></textarea>
                                                            </td>
                                                            <td>
                                                                <input
                                                                        v-model="item.Cantidad"
                                                                        @input="hacerCalculos(index, item)"
                                                                        type="number"
                                                                        class="form-control form-control-sm"
                                                                        style="width:80px" >
                                                            </td>
                                                            <td>
                                                                <input
                                                                    v-model="item.PrecioUnitario"
                                                                    @input="hacerCalculos(index, item)"
                                                                    type="number"
                                                                    class="form-control form-control-sm"
                                                                    style="width:120px" >
                                                            </td>
                                                            <td>
                                                                <input
                                                                        v-model="item.Importe"
                                                                        type="text"
                                                                        class="form-control form-control-sm"
                                                                        style="width:120px" readonly>
                                                            </td>

                                                            <td class="text-center">
                                                                <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pId="item.IdClienteSucursal" :pEmitSeccion="ConfigListDetalle.EmitSeccion" :segurity="segurity">
                                                                    <template slot="btnaccion">
                                                                        <button type="button" v-b-tooltip.hover.Top title="Eliminar" class="btn btn-danger btn-icon mr-1" @click="eliminarDetalle(index)">
                                                                            <i class="fas fa-trash-alt"></i>
                                                                        </button>
                                                                    </template>
                                                                </CBtnAccion>
                                                            </td>

                                                        </tr>
                                                        <CSinRegistros :pContIF="objDetalleRecoleccion.length" :pColspan="6" />
                                                    </template>
                                                </CTablita>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                        <label>Subtotal:</label>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <input v-model="objRecoleccion.Subtotal" disabled type="text" class="form-control from-control-sm" >
                                                                    </div>
                                                                </div>

                                                                <div class="form-group form-row mt-4">
                                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">

                                                                        <div class="form-check">
                                                                            <input v-model="objRecoleccion.ConIva" @change="hacerCalculos" type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                            <label class="form-check-label" for="exampleCheck1">IVA:</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <input v-model="objRecoleccion.Iva"  disabled type="text" class="form-control from-control-sm" >
                                                                    </div>
                                                                </div>


                                                                <div class="form-group form-row mt-4">
                                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                        <label>Total:</label>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                                        <input v-model="objRecoleccion.Total" disabled type="text" class="form-control from-control-sm" >
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                <CBtnSave :poBtnSave="oBtnSave" />
                            </div>

                        </div>
                    </template>
                </CLoader>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModalProductos" :poBtnSave="poBtnSaveProductos">
            <template slot="Form">
                <listProductos :poBtnSave="poBtnSaveProductos"></listProductos>
            </template>
        </CModal>

    </div>
</template>
<script>
import moment from "moment"
import CList from "@/components/CList.vue";
import CValidation from "@/components/CValidation.vue";
import CLoader from "@/components/CLoader.vue";
import Template from "@/views/template/Template.vue";
import CBtnSave from "@/components/CBtnSave.vue";
import BuscadorProveedores from "@/components/BuscadorProveedores.vue";
import CTablita from "@/components/CTablita.vue";
import CBtnAccion from "@/components/CBtnAccion.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";
import CModal from "@/components/CModal.vue";
import listProductos from "@/views/modulos/produccion/ordenesproduccion/ListProductos.vue";


const EmitEjecuta = 'seccionFormProduccion';
const EmitEjecutaDetalle = 'seccionFormDetalle';
const EmitEjecutaProductos = 'seccionSelectProductos';

export default {
    name: "FormOrdenRecoleccion",
    props: ["Id"],
    components: {
        Template,
        CList,
        CValidation,
        CLoader,
        CBtnSave,
        BuscadorProveedores,
        CTablita,
        CBtnAccion,
        CSinRegistros,
        CModal,
        listProductos
    },
    data() {
        return {
            errorvalidacion:[],
            segurity:{},
            masks: {
                input: "YYYY-MM-DD",
            },


            ConfigList: {
                ShowTitleFirst:    false,
                Title:             "Detalle Orden Recolección",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     true,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },

            ConfigLoad: {
                ShowLoader: true,
                ClassLoad:  false,
            },

            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
            },

            objRecoleccion:{
                IdRecoleccion: 0,
                IdSucursal: 0,
                IdVehiculo: 0,
                IdProveedor: '',
                FolioRecoleccion: '',
                Fecha: '',
                ConIva: '',
                Subtotal: 0,
                Iva: 0,
                Total: 0,
            },
            objDetalleRecoleccion:[],

            objProveedor:{
                IdProveedor: 0,
                Nombre: '',
                Direccion: ''
            },


            ConfigListDetalle:{
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecutaDetalle,
            },

            ConfigModalProductos: {
                ModalTitle:  "Productos",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecutaProductos,
                ModalSize:   'lg',
                ShowFooter: true,
            },
            poBtnSaveProductos:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecutaProductos,
                ShowBtnSave: true,
                IdObjeto: 0,
            },
        }
    },
    methods: {
        getProveedor() {

            this.$http.get( `/proveedores/${this.objRecoleccion.IdProveedor}` )
                .then( res => {
                    const proveedor = res.data.data;
                    this.objProveedor = proveedor;

                }).catch( err => {

                this.limpiaProveedor();

            });
        },

        setProveedor( proveedor = {} ) {

            const buscadorProveedor = this.$refs.buscadorProveedor;

            buscadorProveedor.proveedores.push({
                label: `${proveedor.Nombre}`,
                key: proveedor.IdProveedor,
                detalle: {
                    direccion: proveedor.Direccion,
                },
            });

        },

        limpiaProveedor(){
            this.objProveedor = {
                IdProveedor:        0,
                Nombre: '',
                Direccion: ''
            }
        },

        Recuperar() {
            this.$http.get("recolecciones/"+this.objRecoleccion.IdRecoleccion)
                .then((res) => {
                    let result = res.data.data;
                    result.proveedor.IdProveedor = parseInt(result.proveedor.IdProveedor);
                    this.objRecoleccion 				= result;
                    this.objRecoleccion.ConIva          = parseInt(this.objRecoleccion.ConIva) > 0 ? true : false;

                    let Fecha1 						    = this.objRecoleccion.Fecha.replace(/-/g,'\/');
                    this.objRecoleccion.Fecha           = Fecha1;
                    this.objProveedor                   = this.objRecoleccion.proveedor;
                    this.setProveedor(this.objProveedor);
                    this.objDetalleRecoleccion          = this.objRecoleccion.detalle_recoleccion


                }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            });
        },

        Guardar(){
            let Fecha1 = '';
            if(this.objRecoleccion.Fecha!=''){
                Fecha1 = moment(this.objRecoleccion.Fecha).format('YYYY-MM-DD');
            }

            this.objRecoleccion.Fecha = Fecha1;

            const bodyParams = {
                IdRecoleccion: this.objRecoleccion.IdRecoleccion,
                IdVehiculo: this.objRecoleccion.IdVehiculo,
                IdProveedor: this.objRecoleccion.IdProveedor,
                ConIva: this.objRecoleccion.ConIva,
                Fecha: this.objRecoleccion.Fecha,
                Subtotal: this.objRecoleccion.Subtotal,
                Iva: this.objRecoleccion.Iva,
                Total: this.objRecoleccion.Total,

                detalleRecoleccion: JSON.stringify(this.objDetalleRecoleccion)
            };

            this.$http.post( '/recolecciones', bodyParams )
                .then( res  => {
                    this.EjecutaConExito(res);
                })
                .catch( err => {
                    this.EjecutaConError(err);
                });
        },

        EjecutaConExito(res) {
            this.oBtnSave.DisableBtn = false;
            this.bus.$emit('RunAlerts_'+this.ConfigList.EmitSeccion,1);
            this.Regresar();
        },

        EjecutaConError(err) {
            this.oBtnSave.DisableBtn = false;

            if (err.response.data.errors) {
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },


        buscaProductos() {
            this.bus.$emit('NewModal_'+EmitEjecutaProductos,this.objRecoleccion.IdCliente);
        },

        Regresar() {
            this.$router.push({name:'recolecciones',params:{}});
        },


        agregarDetalle() {

            this.objDetalleRecoleccion.push({
                Descripcion: "",
                Cantidad: 1,
                PrecioUnitario: 0,
                Importe: 0
            });

        },


        eliminarDetalle( index ) {

            this.objDetalleRecoleccion.splice( index, 1 );
            this.hacerCalculos();

        },


        hacerCalculos() {

            let subtotal    = 0.00;
            let iva         = 0.00;
            let total       = 0;

            this.objDetalleRecoleccion.forEach( (element) => {
                let pUnitario = 0;
                let cantidad = 0;
                let importeFinal = 0;

                // EVALUAR SI EL MONTO ES NAN O MAYOR A 0
                if(!isNaN(element.PrecioUnitario) && parseFloat(element.PrecioUnitario) > 0){
                    pUnitario = parseFloat(element.PrecioUnitario);
                }

                // EVALUAR SI EL MONTO ES NAN O MAYOR A 0
                if(!isNaN(element.Cantidad) && parseFloat(element.Cantidad) > 0){
                    cantidad = parseFloat(element.Cantidad);
                }

                importeFinal = (pUnitario * cantidad)
                element.Importe =  importeFinal.toFixed(2);
                subtotal += parseFloat(element.Importe);

            });

            this.objRecoleccion.Subtotal = subtotal.toFixed(2);

            // EVALUAR SI SE REQUIERE O NO EL IVA DEL SUBTOTAL
            if(this.objRecoleccion.ConIva) {
                iva     = (subtotal * 0.16);
                iva     = isNaN(iva) ? 0 : iva;

                total   = (iva + subtotal)
                total   = isNaN(total) ? 0 : total;

                this.objRecoleccion.Iva     = iva.toFixed(2);
                this.objRecoleccion.Total   = total.toFixed(2);

            }else {
                this.objRecoleccion.Iva     = iva.toFixed(2);
                this.objRecoleccion.Total   = subtotal.toFixed(2);
            }
        },

    },
    computed: {},
    created() {
        this.bus.$off("Save_"+EmitEjecuta);
    },
    mounted() {
        this.limpiaProveedor();

        if (this.Id > 0) {
            this.objRecoleccion.IdRecoleccion = this.Id;
            this.Recuperar();

        } else {
            this.isReady               = true;
            this.ConfigLoad.ShowLoader = false;
        }

        this.bus.$on("Save_"+EmitEjecuta, () => {
            this.Guardar();
        });

        this.bus.$on("EmitReturn", () => {
            this.Regresar();
        });
    }

}
</script>
