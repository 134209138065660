<template>
    <span>
        {{GetVal}}

        <div class="btn-group btn-ab">
            <button type="button" class="btn btn-ellip btn-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-icon dropdown-menu-right">
                <template v-if="objSegurity.Edit">
                    <span v-if="ConfigAccion.ShowBtnEdit">
                        <a class="dropdown-item" @click="EditRegister(ConfigAccion.Id)" v-if="ConfigAccion.IsModal">
                            <i class="fas fa-pencil texto-primary fa-1x fa-fw"></i>
                            Editar
                        </a>

                        <a class="dropdown-item" @click="EditRegister(ConfigAccion.Id)" v-else>
                            <i class="fas fa-pencil texto-primary fa-1x fa-fw"></i>
                            Editar
                        </a>
                    </span>
                </template>

                <template v-if="objSegurity.Delete">
                    <a class="dropdown-item" @click="DeleteRegister(ConfigAccion.Id)" v-if="ConfigAccion.ShowBtnDelete">
                        <i class="fas fa-trash texto-danger fa-1x fa-fw"></i>
                        Eliminar
                    </a>
                </template>
                
                <slot name="btnaccion"></slot>
            </div>
        </div>

    </span>
</template>

<script>

export default {
    name:  "CBtnAccion",
    props:['pShowBtnEdit','pShowBtnDelete' ,'pIsModal','pId','pGoRoute','pObj','pHide','pEmitSeccion','segurity'],
    data() {
        return {
            ConfigAccion:{
                Id: '',
                GoRoute: '',
                IsModal: true,
                ShowBtnEdit: true,
                ShowBtnDelete: true,
                EmitSeccion: '',
                Hide: {},
                Obj: {},
                objSegurity:{},
            }
        }
    },
    methods: {
        EditRegister(Id)
        {
            if (this.ConfigAccion.IsModal == true)
            {
                this.bus.$emit('NewModal_'+this.ConfigAccion.EmitSeccion,Id,this.ConfigAccion.Obj);
                if (this.Hide != undefined){
                    this.HideAction(this.Hide.Ocultar,this.Hide.Accion);
                }
            }
            else
            {
                this.$router.push({name:this.ConfigAccion.GoRoute, params:{Id:Id,Obj:this.ConfigAccion.Obj}})
            }
        },
        DeleteRegister(Id)
        {
            this.bus.$emit('Delete_'+this.ConfigAccion.EmitSeccion,Id); 
        },
        HideAction(Ocultar,Accion)
        {
            // SOLO RECIBE 2 ACCIONES, UNICAMENTE SIRVE PARA EL CASO DE MODAL 
            // 1-Save PARA OCULTAR BOTON GUARDAR
            // 2-Footer PARA OCULTAR TODO EL PIE
            if(Accion == 'Save'){
                this.bus.$emit('HideSave_'+this.ConfigAccion.EmitSeccion,Ocultar);
            }
            else
            {
                this.bus.$emit('HideFooter_'+this.ConfigAccion.EmitSeccion,Ocultar);
            }
        }
    },
    created(){
    },
    mounted(){
    },
    computed:{
        GetVal()
        {
            if (this.pShowBtnEdit != undefined){
                this.ConfigAccion.ShowBtnEdit = this.pShowBtnEdit;
            }
            if (this.pShowBtnDelete != undefined){
                this.ConfigAccion.ShowBtnDelete = this.pShowBtnDelete;
            }
            if (this.pIsModal != undefined){
                this.ConfigAccion.IsModal = this.pIsModal;
            }
            if (this.pId != undefined){
                this.ConfigAccion.Id = this.pId;
            }
            if (this.pGoRoute != undefined){
                this.ConfigAccion.GoRoute = this.pGoRoute;
            }
            if (this.pObj != undefined){
                this.ConfigAccion.Obj = this.pObj;
            }
            if (this.pEmitSeccion != undefined){
                this.ConfigAccion.EmitSeccion = this.pEmitSeccion;
            }
            if (this.pHide != undefined){
                this.Hide = this.pHide
            }
            if(this.segurity!=undefined){
                this.objSegurity = this.segurity;
            }

            return '';
        }
    },
}
</script>