<template>
    <div>
        <CList :pConfigList="ConfigList">
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">D. Personales</a>
                                    </li>

                                </ul>

                                <div class="tab-content shadow-sm" id="myTabContent">
                                    <!--Parte uno-->
                                    <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                    <fieldset>
                                                        <legend class="col-form-label">&nbsp;Datos&nbsp;</legend>
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                                <div class="avatar-upload">
                                                                    <div class="avatar-edit">
                                                                        <input id="file" @change="$uploadImagePreview($event,ValidElement, Array('Img','imagePreview'))" ref="fileImg" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                                        <label for="file"></label>
                                                                    </div>
                                                                    <div class="avatar-preview">
                                                                        <div id="imagePreview" :style="'background-image: url('+RutaFile+objUsuarioEmpleado.UrlImg+');'" :src="RutaFile+objUsuarioEmpleado.UrlImg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item1">Nombre(s) <span class="text-danger">*</span></label>
                                                                        <input type="text" name="item1" id="item1" class="form-control" placeholder="Nombre(s)" v-model="objUsuarioEmpleado.Nombre">
                                                                        <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item2">Apellido Paterno <span class="text-danger">*</span></label>
                                                                        <input type="text" name="item2" id="item2" class="form-control" placeholder="Apellido Paterno" v-model="objUsuarioEmpleado.ApellidoPat">
                                                                        <CValidation v-if="this.errorvalidacion.ApellidoPat" :Mensaje="'*'+errorvalidacion.ApellidoPat[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item3">Apellido Materno <span class="text-danger">*</span></label>
                                                                        <input type="text" name="item3" id="item3" class="form-control" placeholder="Apellido Materno" v-model="objUsuarioEmpleado.ApellidoMat">
                                                                        <CValidation v-if="this.errorvalidacion.ApellidoMat" :Mensaje="'*'+errorvalidacion.ApellidoMat[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item4">Género <span class="text-danger">*</span></label>
                                                                        <select name="item4" id="item4" class="form-control form-select" v-model="objUsuarioEmpleado.empleados.Genero">
                                                                            <option value="0">--Seleccionar--</option>
                                                                            <option v-for="(item, index) in ListaGeneros" :key="index" :value="item.id">{{item.label}}</option>
                                                                        </select>
                                                                        <CValidation v-if="this.errorvalidacion.Genero" :Mensaje="'*'+errorvalidacion.Genero[0]"/>
                                                                    </div>
                                                                </div>

                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label>Fecha de Nacimiento <span class="text-danger">*</span></label>
                                                                        <v-date-picker  :masks="masks" :popover="{ visibility: 'focus' }" locale="es"  @input="CalcularEdad()" v-model="objUsuarioEmpleado.empleados.FechaNacimiento" :max-date="Obten18Anios()"><!---->
                                                                            <template v-slot="{ inputValue, inputEvents }">
                                                                                <input class="form-control cal" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly/>
                                                                            </template>
                                                                        </v-date-picker>
                                                                        <CValidation v-if="this.errorvalidacion.FechaNacimiento" :Mensaje="'*'+errorvalidacion.FechaNacimiento[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item5">Edad</label>
                                                                        <input type="text" name="item5" id="item5" class="form-control" readonly placeholder="Edad" v-model="objUsuarioEmpleado.empleados.Edad">
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item6">Estado Civil <span class="text-danger">*</span></label>
                                                                        <select name="item6" id="item6" class="form-control form-select" v-model="objUsuarioEmpleado.empleados.EstadoCivil">
                                                                            <option value="">--Seleccionar--</option>
                                                                            <option v-for="(item, index) in estadosCiviles" :key="index" :value="item.id">{{item.label}}</option>
                                                                        </select>
                                                                        <CValidation v-if="this.errorvalidacion.EstadoCivil" :Mensaje="'*'+errorvalidacion.EstadoCivil[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item7">Nacionalidad <span class="text-danger">*</span></label>
                                                                        <select class="form-control" v-model="objUsuarioEmpleado.empleados.Nacionalidad">
                                                                            <option value="">--Seleccionar--</option>
                                                                            <option value="Mexicana">Mexicana</option>
                                                                            <option value="Extranjera">Extranjera</option>
                                                                        </select>
                                                                        <CValidation v-if="this.errorvalidacion.Nacionalidad" :Mensaje="'*'+errorvalidacion.Nacionalidad[0]"/>
                                                                    </div>
                                                                </div>

                                                                <div class="form-group form-row">
                                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                        <label for="item8">Teléfono <span class="text-danger">*</span></label>
                                                                        <input type="text" name="item8" id="item8" class="form-control" placeholder="Ejm. 9999999999 " v-model="objUsuarioEmpleado.Telefono" @input="$onlyNums($event,objUsuarioEmpleado,'Telefono')" maxlength="10">
                                                                        <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*'+errorvalidacion.Telefono[0]"/>
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                        <label for="item9">Correo <span class="text-danger">*</span></label>
                                                                        <input type="text" name="item9" id="item9" class="form-control" placeholder="example@example.com" v-model="objUsuarioEmpleado.Correo" >
                                                                        <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*'+errorvalidacion.Correo[0]"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                    <fieldset>
                                                        <legend class="col-form-label">&nbsp;Domicilio&nbsp;</legend>

                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label for="item10">Calle <span class="text-danger">*</span></label>
                                                                <input type="text" name="item10" id="item10" class="form-control" placeholder="Ingrese Calle" v-model="objUsuarioEmpleado.empleados.Calle" >
                                                                <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*'+errorvalidacion.Calle[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item11">Número Exterior <span class="text-danger">*</span></label>
                                                                <input type="text" name="item11" id="item11" class="form-control" placeholder="Ingrese Número Exterior" v-model="objUsuarioEmpleado.empleados.NoExt">
                                                                <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*'+errorvalidacion.NoExt[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item11">Número Interior</label>
                                                                <input type="text" name="item12" id="item12" class="form-control" placeholder="Ingrese Número Interior" v-model="objUsuarioEmpleado.empleados.NoInt">
                                                                <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*'+errorvalidacion.NoInt[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item13">Codigo Postal <span class="text-danger">*</span></label>
                                                                <input type="text" name="item14" id="item14" class="form-control" placeholder="Ingrese Codigo Postal" v-model="objUsuarioEmpleado.empleados.Cp" @input="$onlyNums($event,objUsuarioEmpleado.empleados,'Cp')" maxlength="10">
                                                                <CValidation v-if="this.errorvalidacion.Cp" :Mensaje="'*'+errorvalidacion.Cp[0]"/>
                                                            </div>

                                                        </div>

                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Cruzamiento1">Cruzamiento 1 <span class="text-danger">*</span></label>
                                                                <input type="text"  name="Cruzamiento1" id="Cruzamiento1" class="form-control" placeholder="Ingrese Cruzamiento 1" v-model="objUsuarioEmpleado.empleados.Cruzamiento1">
                                                                <CValidation v-if="this.errorvalidacion.Cruzamiento1" :Mensaje="'*'+errorvalidacion.Cruzamiento1[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Cruzamiento2">Cruzamiento 2 <span class="text-danger">*</span></label>
                                                                <input type="text"  name="Cruzamiento2" id="Cruzamiento2" class="form-control" placeholder="Ingrese Cruzamiento 2" v-model="objUsuarioEmpleado.empleados.Cruzamiento2">
                                                                <CValidation v-if="this.errorvalidacion.Cruzamiento2" :Mensaje="'*'+errorvalidacion.Cruzamiento2[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Colonia">Colonia <span class="text-danger">*</span></label>
                                                                <input type="text"  name="Colonia" id="Colonia" class="form-control" placeholder="Ingrese Colonia" v-model="objUsuarioEmpleado.empleados.Colonia">
                                                                <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*'+errorvalidacion.Colonia[0]"/>
                                                            </div>

                                                        </div>

                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="item14">Estado <span class="text-danger">*</span></label>
                                                                <select v-model="objUsuarioEmpleado.IdEstado" name="item15" id="item15" class="form-control form-select" @change="GetMunicipios()">
                                                                    <option value="0">--Seleccionar--</option>
                                                                    <option v-for="(item, index) in ListaEstados" :key="index" :value="item.IdEstado">
                                                                        {{ item.Nombre }}
                                                                    </option>
                                                                </select>
                                                                <CValidation v-if="this.errorvalidacion.IdEstado" :Mensaje="'*'+errorvalidacion.IdEstado[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="item15">Municipio <span class="text-danger">*</span></label>
                                                                <select v-model="objUsuarioEmpleado.IdMunicipio"  name="item15" id="item15" class="form-control form-select">
                                                                    <option :value="0">--Seleccionar--</option>
                                                                    <option v-for="(item, index) in ListaMunicipios" :key="index" :value="item.IdMunicipio">
                                                                        {{ item.Nombre }}
                                                                    </option>
                                                                </select>
                                                                <CValidation v-if="this.errorvalidacion.IdMunicipio" :Mensaje="'*'+errorvalidacion.IdMunicipio[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Referencias">Referencias</label>
                                                                <textarea id="Referencias" placeholder="Ingrese Referencias" rows="2" wrap="soft" class="form-control" v-model="objUsuarioEmpleado.empleados.Referencias"></textarea>
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                    <fieldset v-show="objUsuarioEmpleado.IdUsuario == 0">
                                                        <legend class="col-form-label">&nbsp;Datos de acceso&nbsp;</legend>
                                                        <div class="form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item16">Usuario <span class="text-danger">*</span></label>
                                                                <input :readonly="blockInput" type="text" name="item16" id="item16" class="form-control" placeholder="Nombre del Usuario" v-model="objUsuarioEmpleado.username" autocomplete="off" >
                                                                <CValidation v-if="this.errorvalidacion.username" :Mensaje="'*'+errorvalidacion.username[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item18">Contraseña</label>
                                                                <input  v-model="objUsuarioEmpleado.password" :type="type1" name="item18" id="item18" class="form-control" placeholder="Contraseña" autocomplete="off">
                                                                <button v-if="objUsuarioEmpleado.password !== ''" @click="ToggleShow" class="button btn-password-formulario" type="button" id="button-addon2">
                                                                    <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                                </button>
                                                                <CValidation v-if="this.errorvalidacion.password" :Mensaje="'*'+errorvalidacion.password[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item19">Confirmar Contraseña</label>
                                                                <input v-model="objUsuarioEmpleado.password_confirmation" :type="type2" name="item19" id="item19" class="form-control" placeholder="Confirmar Contraseña" autocomplete="off">
                                                                <button v-if="objUsuarioEmpleado.password_confirmation !== ''" @click="ToggleShowConfirm" class="button btn-password-formulario" type="button" id="button-addon2" >
                                                                    <i class="far icono-password" :class="{ 'fa-eye-slash': showPasswordConfirm, 'fa-eye': !showPasswordConfirm }"></i>
                                                                </button>
                                                                <CValidation v-if="this.errorvalidacion.password_confirmation" :Mensaje="'*'+errorvalidacion.password_confirmation[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                                <label for="IdSucursal">Sucursal <span class="text-danger">*</span></label>
                                                                <select id="IdSucursal" v-model="objUsuarioEmpleado.IdSucursal" class="form-control form-select" >
                                                                    <option :value="0">--Seleccionar--</option>
                                                                    <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                                                        {{ item.Nombre }}
                                                                    </option>
                                                                </select>
                                                                <label>
                                                                    <CValidation v-if="this.errorvalidacion.IdSucursal" :Mensaje="'*' + errorvalidacion.IdSucursal[0]"></CValidation>
                                                                </label>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item17">Perfil <span class="text-danger">*</span></label>
                                                                <select @change="ListaPerfiles" v-model="objUsuarioEmpleado.IdPerfil" name="item17" id="item17" class="form-control form-select">
                                                                    <option value="0">--Seleccionar--</option>
                                                                    <option  v-for="(item, index) in ListaDePerfiles" :key="index" :value="item.IdPerfil" >{{item.Nombre}}</option>
                                                                </select>
                                                                <CValidation v-if="this.errorvalidacion.IdPerfil" :Mensaje="'*'+errorvalidacion.IdPerfil[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                                <label for="item20">Puesto</label>
                                                                <select @change="ListaPuestos" v-model="objUsuarioEmpleado.IdPuesto" name="item20" id="item20" class="form-control form-select">
                                                                    <option value="0">--Seleccionar--</option>
                                                                    <option  v-for="(item, index) in ListaDePuestos" :key="index" :value="item.IdPuesto" >{{item.Nombre}}</option>
                                                                </select>
                                                                <CValidation v-if="this.errorvalidacion.IdPuesto" :Mensaje="'*'+errorvalidacion.IdPuesto[0]"/>
                                                            </div>
                                                        </div>
                                                    </fieldset>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CBtnSave :poBtnSave="oBtnSave" />
                                </div>
                            </div>
                        </div>
                    </template>
                </CLoader>
            </template>
        </CList>

    </div>
</template>


<script>
    import CList          from "../../../../components/CList";
    import CLoader        from "../../../../components/CLoader";
    import CModal         from "../../../../components/CModal";
    import CBtnSave from "@/components/CBtnSave.vue";
    import Configs        from '@/helpers/VarConfig.js';
    import StaticComboBox from '@/helpers/StaticComboBox.js';
    import moment         from 'moment'
    import ListEquipos    from '@/views/modulos/empresa/empleados/ListEquipos.vue';
    const  EmitEjecuta    =    'seccionEmpleado';
    const  EmitEquipos    =    'seccionEquipos';

    export default {
        name: 'FormEmpleados',
        props:['Id'],
        components:{
            ListEquipos,
            CList,
            CLoader,
            CModal,
            CBtnSave

        },
        data() {
            return{
                RutaFile:              '',
                RutaPdf:               '',
                RutaPdfEvi:            '',
                type1:                 'password',
                type2:                 'password'  ,
                showPassword:          false,
                showPasswordConfirm:   false,
                Img:                   null,
                checked:               false,
                ListaGeneros:          StaticComboBox.Genero,
                estadosCiviles:        StaticComboBox.EstadosCiviles,
                ValidElement:          Configs.validImage2m,
                validElementFile:      Configs.validFile5m,
                NameFile:              Configs.validFile5m.NameFile,
                NameEvi:               Configs.validFile5m.NameFile,
                errorvalidacion:       [],

                ListaEstados:          [],
                ListaMunicipios:       [],
                ListaDePerfiles:       [],
                ListaDePuestos:        [],

                ListaSucursalesArray:  [],


                blockInput:            false,
                segurity:              {},

                ConfigList:{
                    Title:          'Formulario Empleados',
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'Form',
                    ShowTitleFirst: false,
                    EmitSeccion:    EmitEjecuta,
                },

                ConfigList2: {
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'List',
                    ShowTitleFirst: false,
                    EmitSeccion:    EmitEjecuta,
                },

                oBtnSave: {
                    toast:         0,
                    IsModal:       false,
                    ShowBtnSave:   true,
                    ShowBtnCancel: false,
                    DisableBtn:    false,
                    EmitSeccion:   EmitEjecuta,
                },

                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },



                objUsuarioEmpleado: {
                    //!usuario
                    IdUsuario:             0,
                    IdSucursal:            0,
                    IdEmpleado:            0,
                    IdPerfil:              0,
                    IdPuesto:              0,
                    IdEstado:              0,
                    IdMunicipio:           0,
                    UsuarioApp:            0,
                    Nombre:                '',
                    ApellidoPat:           '',
                    ApellidoMat:           '',
                    NombreCompleto:        '',
                    Correo:                '',
                    Telefono:              '',
                    username:              '',
                    password:              '',
                    password_confirmation: '',
                    Imagen:                '',
                    UrlImg:                '',
                    Estatus:               '',
                    Comentarios:           '',
                    datos_fam:             [],
                    datos_her:             [],
                    datos_ref:             [],
                    empleados: {
                        Rfc:             '',
                        Calle:           '',
                        NoInt:           '',
                        NoExt:           '',
                        Cruzamiento1:    '',
                        Cruzamiento2:    '',
                        Colonia:         '',
                        Cp:              '',
                        Referencias:     '',
                        FechaNacimiento: '',
                        EstadoCivil:     '',
                        Finiquito:       '',
                        FechaAlta:       '',
                        FechaBaja:       '',
                        FechaFiniquito:  '',
                        Genero:          0,
                        Nacionalidad:    '',
                        Edad:            '',
                    },
                    doc_evidencia: {
                        Anio:                "",
                        Evidencia:           "",
                        Huella:              "",
                        IdEmpleado:          0,
                        IdEmpleadoEvidencia: 0,
                    },
                },

                masks: {
                    input: "YYYY-MM-DD",
                },
            }
        },
        methods:{
            async Guardar() {
                let formData = new FormData();
                formData.set('IdUsuario',             this.objUsuarioEmpleado.IdUsuario);
                formData.set('IdSucursal',            this.objUsuarioEmpleado.IdSucursal);
                formData.set('IdEmpleado',            this.objUsuarioEmpleado.IdEmpleado);
                formData.set('IdPerfil',              this.objUsuarioEmpleado.IdPerfil);
                formData.set('IdPuesto',              this.objUsuarioEmpleado.IdPuesto);
                formData.set('IdEstado',              this.objUsuarioEmpleado.IdEstado);
                formData.set('IdMunicipio',           this.objUsuarioEmpleado.IdMunicipio);

                formData.set('Nombre',                this.objUsuarioEmpleado.Nombre);
                formData.set('ApellidoPat',           this.objUsuarioEmpleado.ApellidoPat);
                formData.set('ApellidoMat',           this.objUsuarioEmpleado.ApellidoMat);


                formData.set('Correo',                this.objUsuarioEmpleado.Correo);
                formData.set('Telefono',              this.objUsuarioEmpleado.Telefono);
                formData.set('Comentarios',           this.objUsuarioEmpleado.Comentarios);
                formData.set('username',              this.objUsuarioEmpleado.username);
                formData.set('password',              this.objUsuarioEmpleado.password);
                formData.set('password_confirmation', this.objUsuarioEmpleado.password_confirmation);
                formData.set('Estatus',               "Activo");
                // PESTAÑA 1
                formData.set('ImagenAntigua', this.objUsuarioEmpleado.UrlImg);

                let Imagen = this.$refs.fileImg.files[0];
                formData.append('Imagen',Imagen);


                // EMPLEADO
                formData.set('Rfc',                   this.objUsuarioEmpleado.empleados.Rfc);
                formData.set('Calle',                 this.objUsuarioEmpleado.empleados.Calle);
                formData.set('NoInt',                 this.objUsuarioEmpleado.empleados.NoInt);
                formData.set('NoExt',                 this.objUsuarioEmpleado.empleados.NoExt);
                formData.set('Cruzamiento1',          this.objUsuarioEmpleado.empleados.Cruzamiento1);
                formData.set('Cruzamiento2',          this.objUsuarioEmpleado.empleados.Cruzamiento2);
                formData.set('Colonia',               this.objUsuarioEmpleado.empleados.Colonia);
                formData.set('Cp',                    this.objUsuarioEmpleado.empleados.Cp);
                formData.set('Referencias',           this.objUsuarioEmpleado.empleados.Referencias);

                formData.set('FechaNacimiento',       moment(this.objUsuarioEmpleado.empleados.FechaNacimiento).format("YYYY-MM-DD"));
                formData.set('EstadoCivil',           this.objUsuarioEmpleado.empleados.EstadoCivil);
                formData.set('Genero',                this.objUsuarioEmpleado.empleados.Genero);
                formData.set('Nacionalidad',          this.objUsuarioEmpleado.empleados.Nacionalidad);
                formData.set('Edad',                  this.objUsuarioEmpleado.empleados.Edad);





                this.errorvalidacion     = [];
                this.oBtnSave.toast      = 0;
                this.oBtnSave.DisableBtn = true;

                await this.$http.post('users', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    this.EjecutaConExito(res);
                }).catch(err=>{
                    this.EjecutaConError(err);
                });
            },

            EjecutaConExito(res) {
                this.oBtnSave.DisableBtn = false;
                this.bus.$emit('RunAlerts_'+this.ConfigList.EmitSeccion,1);
                this.Regresar();
            },

            EjecutaConError(err) {
                this.oBtnSave.DisableBtn = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.oBtnSave.toast  = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }

            },

            Recuperar() {
                this.$http.get("users/getUsuEmple/"+this.objUsuarioEmpleado.IdEmpleado).then( (res) => {
                    this.objUsuarioEmpleado    = res.data.data;
                    this.segurity              = res.data.segurity;
                    this.RutaFile              = res.data.rutaFile;

                    this.objUsuarioEmpleado.password              = '';
                    this.objUsuarioEmpleado.password_confirmation = '';
                    this.blockInput                               = true;

                    let FechaNac = this.objUsuarioEmpleado.empleados.FechaNacimiento;

                    if(FechaNac !== '' && FechaNac != null){
                        let Fecha1 = FechaNac.replace(/-/g,'\/');
                        this.objUsuarioEmpleado.empleados.FechaNacimiento = Fecha1;
                    }


                    // PARA LOS PERMISOS
                    if(!this.segurity.BtnSave){
                        this.oBtnSave.ShowBtnSave = false;
                    } else if(this.segurity.BtnSave){
                        this.oBtnSave.ShowBtnSave = true;
                    }

                    this.GetMunicipios();
                    this.CalcularEdad();

                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },

            async ListaPerfiles(){
                await this.$http.get('perfiles', {
                    params:{
                        simple: 1
                    }
                }).then( (res) => {
                    this.ListaDePerfiles = res.data.data.perfiles;
                });
            },

            async ListaPuestos() {
                await this.$http.get('puestos', {
                    params:{}
                }).then( (res) => {
                    this.ListaDePuestos = res.data.data.Puesto.data;
                });
            },

            async GetEstados() {
                await this.$http.get('estados').then(res => {
                    this.ListaEstados = res.data.data;

                }).catch(err =>{
                    this.ListaEstados = [];
                });
            },

            GetMunicipios() {
                if (parseInt(this.objUsuarioEmpleado.IdEstado) > 0) {
                    this.ListaMunicipios = [];

                    this.$http.get('municipios',{
                        params:{
                            IdEstado: this.objUsuarioEmpleado.IdEstado
                        }
                    }).then(res => {
                        this.ListaMunicipios = res.data.data;

                    }).catch(err =>{
                        this.ListaMunicipios = [];

                    });
                }else {
                    this.ListaMunicipios = [];
                }
            },

            ListaSucursales(){
                this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                    .then((res) => {
                        this.ListaSucursalesArray = res.data.data;
                    });
            },

            Limpiar() {
                this.objUsuarioEmpleado =  {

                    //!usuario
                    IdUsuario:       0,
                    IdSucursal:      0,
                    IdEmpleado:      0,
                    IdPerfil:        0,
                    IdPuesto:        0,
                    IdEstado:        0,
                    IdMunicipio:     0,
                    Nombre:          '',
                    ApellidoPat:     '',
                    ApellidoMat:     '',
                    NombreCompleto:  '',
                    Correo:          '',
                    Telefono:        '',
                    username:        '',
                    password:        '',
                    password_confirmation: '',
                    Imagen:           '',
                    UrlImg:           '',
                    Estatus:          '',
                    UsuarioApp:       0,

                    empleados: {
                        Rfc: '',
                        Calle: '',
                        NoInt: '',
                        NoExt: '',
                        Cruzamiento1: '',
                        Cruzamiento2: '',
                        Colonia: '',
                        Cp: '',
                        Referencias: '',

                        FechaNacimiento: '',
                        EstadoCivil: '',
                        Finiquito: '',
                        FechaAlta: '',
                        FechaBaja: '',

                        FechaFiniquito: '',
                        Genero: 0,
                        Nacionalidad: '',
                        Edad: '',
                    },


                }
            },

            CalcularEdad(){
                let edad     = this.objUsuarioEmpleado.empleados.FechaNacimiento;
                var hoy      = new Date();
                var fechaNac = new Date(edad);
                var edadAct  = hoy.getFullYear() - fechaNac.getFullYear();
                var m        = hoy.getMonth() - fechaNac.getMonth();

                if (m < 0 || (m === 0 && hoy.getDate() < fechaNac.getDate())) {
                    edad--;
                }

                this.objUsuarioEmpleado.empleados.Edad=edadAct;
            },

            Obten18Anios() {
                let anio = moment(new Date()).subtract(18, 'years');
                anio     = new Date(anio);
                return anio
            },



            ToggleShow() {

                if (this.showPassword = !this.showPassword) {
                    this.type1 = 'text'
                } else {
                    this.type1 = 'password'
                }
            },

            ToggleShowConfirm() {

                if (this.showPasswordConfirm = !this.showPasswordConfirm) {
                    this.type2 = 'text'
                } else {
                    this.type2 = 'password'
                }
            },

            Regresar() {
                this.$router.push({name:'empleados',params:{}});
            },
        },
        created(){
            this.oBtnSave.toast = 0;
            this.bus.$off('EmitReturn');
            this.bus.$off('pAsignarEquipo');
            this.bus.$off('Save_'+this.ConfigList.EmitSeccion);
        },
        mounted(){
            this.oBtnSave.DisableBtn = false;
            this.Limpiar();
            this.ListaPerfiles();
            this.ListaPuestos();
            this.GetEstados();
            this.ListaSucursales();

            this.bus.$on('Save_'+this.ConfigList.EmitSeccion,()=>
            {
                this.Guardar();
            });

            if(this.Id!=''){
                this.objUsuarioEmpleado.IdEmpleado=this.Id;
                this.Recuperar();
            }else {
                this.ConfigLoad.ShowLoader = false;
            }

            this.bus.$on('EmitReturn',()=>
            {
                this.Regresar();
            });

        },
    }

</script>
