<template>
    <div>


    </div>
</template>

<script>
import CSinRegistros from "../../../components/CSinRegistros";
import moment from "moment";

export default {
    name:  "BienvenidaSitema",
    props: [],
    components: {
        CSinRegistros,
    },
    data() {
        return {

        };
    },
    methods: {
        async ListaEstados() {
            await this.$http
                .get("estados", {
                    params: {},
                })
                .then((res) => {
                    this.ListaEstadosArray = res.data.data;
                    if (this.ListaEstadosArray != undefined) {
                        sessionStorage.setItem("ListaEstadosArray", JSON.stringify(this.ListaEstadosArray));
                    }
                });
        },


    },

    created() {
        this.ListaEstados();
    },
};

</script>
