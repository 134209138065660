<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="Nombre">Finiquito</label>
                                    <input type="text" v-model="objEmpleado.Finiquito" maxlength="250" class="form-control" placeholder="Ingresar Cantidad" @input="$number_decimal($event,objEmpleado,'Finiquito')" />
                                    <CValidation v-if="this.errorvalidacion.Finiquito" :Mensaje="'*'+errorvalidacion.Finiquito[0]"/>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="IdSucursal">Fecha Finiquito</label>
                                    <v-date-picker :masks="masks" :popover="{ visibility: 'focus'}" locale="es" v-model="objEmpleado.FechaFiniquito" :maxDate="new Date()">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control cal" name="FechaFiniquito[]" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly/>
                                        </template>
                                    </v-date-picker>
                                    <CValidation v-if="this.errorvalidacion.FechaFiniquito" :Mensaje="'*' + errorvalidacion.FechaFiniquito[0]"/>
                                </div>
                            </div>

                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import moment from "moment"

export default {
    name:  "FormFiniquito",
    props: ['poBtnSave'],
    data() {
        return {
            Emit:               this.poBtnSave.EmitSeccion,
            errorvalidacion:    [],
            ListaSucursalesArr: [],
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objEmpleado:{
                IdEmpleado: 0,
                IdUsuario: 0,
                Finiquito: '',
                FechaFiniquito: '',
            },
            masks: {
                input: "YYYY-MM-DD",
            },
        }
    },
    methods: {
        async Guardar()
        {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;

            let Fecha = '';
            if(this.objEmpleado.FechaFiniquito !=''){
                Fecha = moment(this.objEmpleado.FechaFiniquito).format('YYYY-MM-DD');
            }

            this.objEmpleado.FechaFiniquito = Fecha;

            this.$http.post(
                'usersLiquidacion', this.objEmpleado
            ).then((res)=>{                 
                this.EjecutaConExito(res);
            }).catch(err=>{   
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast      = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_seccionEmpleadosList');
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        Limpiar()
        {
            this.objEmpleado = {
                IdEmpleado: 0,
                IdUsuario: 0,
                Finiquito: '',
                FechaFiniquito: '',
            };
            this.errorvalidacion = [];
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=> {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });
            this.Limpiar();
            
            this.objEmpleado.IdEmpleado = obj.IdEmpleado;
            this.objEmpleado.IdUsuario = obj.IdUsuario;
            this.ConfigLoad.ShowLoader = false;
        });
    },
}
</script>