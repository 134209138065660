<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="header">
                <th class="td-sm"></th>
                <th>Nombre</th>
                <th>Direccion</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{lista.Nombre}}</td>
                    <td>{{lista.Direccion}}</td>

                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdProveedor" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                            <template slot="btnaccion">
                            </template>
                        </CBtnAccion>
                    </td>
                </tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistros>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
            <template slot="Form">
                <Form :poBtnSave="oBtnSave"></Form>
            </template>
        </CModal>
    </div>
</template>

<script>

import CList            from "@/components/CList.vue";
import CModal           from "@/components/CModal.vue";
import CSinRegistros    from "@/components/CSinRegistros.vue";
import Form             from './Form.vue';
import Configs          from '@/helpers/VarConfig.js';
import CBtnAccion       from "@/components/CBtnAccion.vue";
const EmitEjecuta =    'seccionProveedores';

export default {
    name: 'ListaProveedores',
    components: {
        CList,
        CModal,
        CSinRegistros,
        CBtnAccion,
        Form
    },
    data() {
        return {
            counterField: 1,
            segurity: { Add:1,Edit:1,Delete:1 },
            ConfigList:{
                Title:         'Listado Proveedores',
                IsModal:       true,
                ShowLoader:    true,
                BtnReturnShow: false,
                EmitSeccion:   EmitEjecuta,
            },
            Filtro:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: 'Buscar..',
                TipoEquipo:  '0',
                EstatusEquipo: ''
            },
            ConfigModal:{
                ModalTitle:  "Formulario Proveedores",
                ModalSize:   'lg',
                EmitSeccion:  EmitEjecuta,
            },
            oBtnSave: {
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta,
            },

            obj:            {},
            ListaArrayRows: [],
            ListaHeader:    [],
        }
    },
    methods: {
        async Lista(){
            this.ConfigList.ShowLoader = true;

            await this.$http.get('proveedores', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                }
            }).then( (res) => {
                this.Filtro.Pagina    	= res.data.data.current_page;
                this.Filtro.TotalItem 	= res.data.data.total;
                this.ListaArrayRows     = res.data.data.data;
                //this.segurity           = res.data.segurity;
                //this.$setStartItem();


            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },

        Eliminar(Id){
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value) {
                    this.$http.delete('proveedores/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                }
            });
        },

    },
    created() {
        this.bus.$off('Delete_'+EmitEjecuta);
        this.bus.$off('List_'+EmitEjecuta);
    },
    mounted() {
        this.Lista();
        this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List_'+EmitEjecuta,()=>
        {
            this.Lista();
        });
    },
}

</script>
