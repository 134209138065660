<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                                    <div class="form-group form-row">

                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="IdSucursal">Sucursal <span class="text-danger">*</span></label>
                                            <select id="IdSucursal" v-model="objVehiculo.IdSucursal" class="form-control form-select">
                                                <option :value="0">--Seleccionar--</option>
                                                <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                                    {{ item.Nombre }}
                                                </option>
                                            </select>
                                            <CValidation v-if="this.errorvalidacion.IdSucursal" :Mensaje="'*' + errorvalidacion.IdSucursal[0]"></CValidation>

                                        </div>

                                    </div>

                                    <!-- VEHICULOS -->
                                        <div class="form-group row">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <label for="Nombre">Nombre</label>
                                                <input type="text" v-model="objVehiculo.NombreVehiculo"   maxlength="250" class="form-control" placeholder="Ingresar Nombre" />
                                                <CValidation v-if="this.errorvalidacion.NombreVehiculo" :Mensaje="'*'+errorvalidacion.NombreVehiculo[0]"/>
                                            </div>
                                        </div>

                                        <div class="form-group form-row" >
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Numero Economico</label>
                                                <input type="text" v-model="objVehiculo.NumeroEconomico"   maxlength="250" class="form-control" placeholder="Ingresar un Numero Economico" />
                                                <CValidation v-if="this.errorvalidacion.NumeroEconomico" :Mensaje="'*'+errorvalidacion.NumeroEconomico[0]" />
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Placa</label>
                                                <input type="text" v-model="objVehiculo.Placa"   maxlength="250" class="form-control" placeholder="Ingresar la Placa" />
                                                <CValidation v-if="this.errorvalidacion.Placa" :Mensaje="'*'+errorvalidacion.Placa[0]" />
                                            </div>
                                        </div>

                                        <div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Marca</label>
                                                <input type="text" v-model="objVehiculo.Marca"   maxlength="250" class="form-control" placeholder="Ingresar una Marca" />
                                                <CValidation v-if="this.errorvalidacion.Marca" :Mensaje="'*'+errorvalidacion.Marca[0]"/>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Modelo</label>
                                                <input type="text" v-model="objVehiculo.Modelo"   maxlength="250" class="form-control" placeholder="Ingresar un Modelo" />
                                                <CValidation v-if="this.errorvalidacion.Modelo" :Mensaje="'*'+errorvalidacion.Modelo[0]"/>
                                            </div>
                                        </div>

                                        <div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Color</label>
                                                <input type="text" v-model="objVehiculo.Color"   maxlength="250" class="form-control" placeholder="Ingresar un Color" />
                                                <CValidation v-if="this.errorvalidacion.Color" :Mensaje="'*'+errorvalidacion.Color[0]"/>
                                            </div>

                                            <!--<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Numero de Serie</label>
                                                <input type="text" v-model="objVehiculo.Serie"   maxlength="250" class="form-control" placeholder="Ingresar el Numero de Serie" />
                                                <CValidation v-if="this.errorvalidacion.Serie" :Mensaje="'*'+errorvalidacion.Serie[0]"/>
                                            </div> -->

                                        </div>

                                        <!--<div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">Póliza</label>
                                                <input type="text" v-model="objVehiculo.Poliza"   maxlength="250" class="form-control" placeholder="Ingresar una Póliza" />
                                                <CValidation v-if="this.errorvalidacion.Poliza" :Mensaje="'*'+errorvalidacion.Poliza[0]"/>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <label for="">N° de Póliza</label>
                                                <input type="text" v-model="objVehiculo.NumPoliza"   maxlength="250" class="form-control" placeholder="Ingresar un N° de Póliza" />
                                                <CValidation v-if="this.errorvalidacion.NumPoliza" :Mensaje="'*'+errorvalidacion.NumPoliza[0]"/>
                                            </div>
                                        </div>-->





                                </div>
                            </div>

                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

import CValidation from "@/components/CValidation.vue";
import $$             from "jquery"
import Configs        from '@/helpers/VarConfig.js';
import StaticComboBox from '@/helpers/StaticComboBox.js';
import moment from "moment";

export default {
    name:  "FormVehiculos",
    props: ['poBtnSave'],
    components:{
        CValidation//CBtnSave
    },
    data() {
        return {
            RutaFile:        '',
            Img:             null,
            Emit:            this.poBtnSave.EmitSeccion,
            ValidElement:    Configs.validImage2m,
            TipoEquipo:      StaticComboBox.Equipo,
            errorvalidacion: [],
            ConfigLoad: {
                ShowLoader: true,
                ClassLoad: false,
            },
            objVehiculo: {
                IdVehiculo:        0,
                IdSucursal:        0,
                NombreVehiculo:    0,
                Marca:           '',
                Modelo:          '',
                Placa:           '',
                Color:           '',
                NumeroEconomico: '',
                //Serie:           '',
                //Poliza: '',
                //NumPoliza: '',
            },

            ListaSucursalesArray: [],
            masks: {
                input: "YYYY-MM-DD",
            },
            tipoFormulario:0,
        }
    },
    methods :
        {
            async Guardar()
            {
                this.errorvalidacion=[];
                this.poBtnSave.toast = 0;
                this.poBtnSave.DisableBtn = true;

                let formData = new FormData();
                formData.set('IdVehiculo',      this.objVehiculo.IdVehiculo);
                formData.set('IdSucursal',      this.objVehiculo.IdSucursal);
                formData.set('NombreVehiculo',  this.objVehiculo.NombreVehiculo);
                formData.set('Marca',           this.objVehiculo.Marca);
                formData.set('Modelo',          this.objVehiculo.Modelo);
                formData.set('Placa',           this.objVehiculo.Placa);
                formData.set('Color',           this.objVehiculo.Color);
                formData.set('NumeroEconomico', this.objVehiculo.NumeroEconomico);
                //formData.set('Serie',           this.objVehiculo.Serie);

                //formData.set('Poliza',          this.objVehiculo.Poliza);
                //formData.set('NumPoliza',       this.objVehiculo.NumPoliza);


                /*let picture = this.$refs.file.files[0];
                formData.append('Imagen',picture);*/

                this.$http.post('vehiculos', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    this.EjecutaConExito(res);
                }).catch(err=>{
                    this.EjecutaConError(err);
                });
            },

            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },

            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                }
                else{
                    this.$toast.error(err.response.data.message);
                }
            },

            Recuperar() {
                this.$http.get("vehiculos/"+this.objVehiculo.IdVehiculo
                ).then( (res) => {
                    this.objVehiculo = res.data.data;
                    //this.RutaFile   = res.data.rutaFile;

                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },

            Limpiar() {
                this.objVehiculo = {
                    IdVehiculo:        0,
                    IdSucursal:        0,
                    NombreVehiculo:    0,
                    Marca:           '',
                    Modelo:          '',
                    Placa:           '',
                    Color:           '',
                    NumeroEconomico: '',
                    //Serie:           '',
                    //Poliza: '',
                    //NumPoliza: '',
                };
                this.errorvalidacion = [];
            },
            async ListaSucursales(){
                await this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                    .then((res) => {
                        this.ListaSucursalesArray = res.data.data;
                    });
            },
        },
    created() {
        this.ListaSucursales();
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id) => {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);

            this.bus.$on('Save_'+this.Emit,() => {
                this.Guardar();
            });

            this.Limpiar();

            if(Id!='') {
                this.objVehiculo.IdVehiculo = Id;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }

        });
    },

    computed:{

    }
}

</script>
