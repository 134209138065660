//import MenuConfigFactura from "@/views/catalogos/configfactura/MenuConfigFactura.vue";
import Facturas from '@/views/modulos/facturacion/List.vue';
import FormularioFacturas from '@/views/modulos/facturacion/FormFacturacion.vue';
import ProveedorFacturacion from '@/views/catalogos/configfactura/proveedorfacturacion/List.vue';
import LlavesTimbrado  from '@/views/catalogos/configfactura/llavestimbrado/List.vue';
import Bancos from '@/views/catalogos/configfactura/banco/List.vue';
import ClaveProdServicios from '@/views/catalogos/configfactura/claveproductoservicio/List.vue';
import FormaPago from '@/views/catalogos/configfactura/formapago/List.vue';
import MetodoPago from '@/views/catalogos/configfactura/metodopago/List.vue';
import Moneda from '@/views/catalogos/configfactura/moneda/List.vue';
import ObjetoImpuesto from '@/views/catalogos/configfactura/objetoimpuesto/List.vue';
import RegimenFiscal from '@/views/catalogos/configfactura/regimenfiscal/List.vue';
import TipoRelacionCfdi from '@/views/catalogos/configfactura/tiporelacioncfdi/List.vue';
import UnidadMedida from '@/views/catalogos/configfactura/unidadmedida/List.vue';
import UsoCfdi from '@/views/catalogos/configfactura/usocfdi/List.vue';

const VarRutasFacturacion = [

    {
        path: "/facturas",
        name: "facturas",
        component: Facturas,
        props: true,
    },
    {
        path: "/formulariofacturas",
        name: "formulariofacturas",
        component: FormularioFacturas,
        props: true,
    },
    {
        path: "/proveedorfacturacion",
        name: "proveedorfacturacion",
        component: ProveedorFacturacion,
        props: true,
    },
    {
        path: "/llavestimbrado",
        name: "llavestimbrado",
        component: LlavesTimbrado,
        props: true,
    },
    {
        path: "/bancos",
        name: "bancos",
        component: Bancos,
        props: true,
    },
    {
        path: "/productoservicios",
        name: "productoservicios",
        component: ClaveProdServicios,
        props: true,
    },
    {
        path: "/formapago",
        name: "formapago",
        component: FormaPago,
        props: true,
    },
    {
        path: "/metodopago",
        name: "metodopago",
        component: MetodoPago,
        props: true,
    },
    {
        path: "/moneda",
        name: "moneda",
        component: Moneda,
        props: true,
    },
    {
        path: "/objetoimpuesto",
        name: "objetoimpuesto",
        component: ObjetoImpuesto,
        props: true,
    },
    {
        path: "/regimenfiscal",
        name: "regimenfiscal",
        component: RegimenFiscal,
        props: true,
    },
    {
        path: "/tiporelacioncfdi",
        name: "tiporelacioncfdi",
        component: TipoRelacionCfdi,
        props: true,
    },
    {
        path: "/unidadmedida",
        name: "unidadmedida",
        component: UnidadMedida,
        props: true,
    },
    {
        path: "/usocfdi",
        name: "usocfdi",
        component: UsoCfdi,
        props: true,
    },
];

export default {
    VarRutasFacturacion
};
