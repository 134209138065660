<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="header">
				<th class="td-sm"></th>
				<th># Cliente</th>
				<th>Nombre Comerial</th>
				<th>Razon Social</th>
				<th>Tipo de Negocio</th>
				<th>Telefono</th>
				<th>Estatus</th>
				<th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
					<td><b>{{ lista.NumCliente }}</b></td>
					<td>{{ lista.NombreComercial }}</td>
					<td>{{ lista.RazonSocial }}</td>
					<td>{{ lista.negocios.Negocio }}</td>
					<td>{{ lista.Telefono }}</td>
					<td><b-badge pill variant="success">{{ lista.Estatus }}</b-badge></td>
					<td class="text-center">
						<CBtnAccion :pGoRoute="ConfigList.GoRoute" :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="false" :pId="lista.IdCliente" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">
                            </template>
						</CBtnAccion>
					</td>
				</tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="8" />
			</template>
		</CList>

	</div>
</template>

<script>

	import Configs    	 from "@/helpers/VarConfig.js";
    import CSinRegistros from "../../../../components/CSinRegistros";
    import CList 		 from "../../../../components/CList";
    import CBtnAccion 	 from "../../../../components/CBtnAccion";
	const EmitEjecuta 	 =    "seccionCliente";

	export default {
		name:  "ListaClientes",
        props: ['sFiltros'],
        components: {
            CList,
            CSinRegistros,
            CBtnAccion
        },
		data() {
			return {
				counterField:	1,
				ListaArrayRows: [],
				segurity: 		{ Add:1,Edit:1,Delete:1},
				obj: 	  		{},
				ConfigList: {
					Title: 			"Listado Clientes",
					IsModal: 		false,
					ShowLoader: 	true,
					BtnReturnShow:  false,
					EmitSeccion: 	EmitEjecuta,
					GoRoute: 		"formularioclientesactivos",
					TitleFirst:     'Inicio',
					BtnNewShow:     true,
				},
				Filtro: {
					Nombre: 	 "",
					Pagina: 	 1,
					Entrada:     25,
					TotalItem:   0,
					Placeholder: "#Cliente / Nombre / Negocio",
				},
			};
		},
		methods: {
			async Lista() {
				this.ConfigList.ShowLoader = true;

				await this.$http.get("clientes", {
						params: {
							TxtBusqueda: this.Filtro.Nombre,
							Entrada: 	 this.Filtro.Entrada,
							Pag: 		 this.Filtro.Pagina
						},
					})
					.then((res) => {
						this.Filtro.Pagina    = res.data.data.current_page;
						this.Filtro.TotalItem = res.data.data.total;
						this.ListaArrayRows   = res.data.data.data;
						//this.segurity         = res.data.segurity;
					})
					.finally(() => {
						this.ConfigList.ShowLoader = false;
						this.$saveFilters('SET');
					});
			},
			Eliminar(Id) {
				this.$swal(Configs.configEliminar).then((result) => {
					if (result.value) {
						this.$http.delete("clientes/" + Id).then((res) => {
							this.$swal(Configs.configEliminarConfirm);
							this.Lista();
						})
						.catch((err) => {
							this.$toast.error(err.response.data.message);
						});
					}
				});
			},
		},
		created() {

			if(this.sFiltros===undefined) {
                this.$saveFilters('GET');
            }

			this.bus.$off("Delete_" + EmitEjecuta);
			this.bus.$off("List_" + EmitEjecuta);

		},
		mounted() {
			this.Lista();
			this.bus.$on("Delete_" + EmitEjecuta, (Id) => {
				this.Eliminar(Id);
			});

			this.bus.$on("List_" + EmitEjecuta, () => {
				this.Lista();
			});

		},
	};

</script>
