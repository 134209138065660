import DashboardProduccion from "../views/modulos/reportes/dashboardproduccion.vue";

const VarRutasReportes = [{
    path: '/dashboardproduccion',
    name: 'dashboardproduccion',
    component: DashboardProduccion,
    props: true
}, ];

export default {
    VarRutasReportes,
}