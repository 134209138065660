<template>
	<CLoader :pConfigLoad="ConfigLoad">
		<template slot="BodyFormLoad">
            <input type="hidden" :value="setLocation">
			<form class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row form-group">
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Nombre">Nombre</label>
                            <input  type="text" v-model="objSucursal.Nombre" maxlength="250" class="form-control" placeholder="Ingresar Nombre de la Sucursal"/>
                            <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*' + errorvalidacion.Nombre[0]"/>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">Teléfono</label>
                            <input type="text" v-model="objSucursal.Telefono" @input="$onlyNums($event,objSucursal,'Telefono')" maxlength="10" class="form-control" placeholder="Ingresar Teléfono"/>
                            <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*' +errorvalidacion.Telefono[0]"/>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label for="Calle">Calle</label>
                            <input type="text" v-model="objSucursal.Calle" maxlength="250" class="form-control" placeholder="Ingresar Calle" />
                            <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*' + errorvalidacion.Calle[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                            <label for="NoExterior">No. Exterior</label>
                            <input type="text" v-model="objSucursal.NoExt" maxlength="250" class="form-control" placeholder="Ingresar No. Exterior" />
                            <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*' + errorvalidacion.NoExt[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                            <label for="NoInterior">No. Interior</label>
                            <input type="text" v-model="objSucursal.NoInt" maxlength="250" class="form-control" placeholder="Ingresar No. Interior" />
                            <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*' + errorvalidacion.NoInt[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                            <label for="Cp">Código Postal</label>
                            <input type="text" v-model="objSucursal.Cp" @input="$onlyNums($event,objSucursal,'Cp')" maxlength="10" class="form-control" placeholder="Ingresar Cp" />
                            <CValidation v-if="this.errorvalidacion.Cp" :Mensaje="'*' + errorvalidacion.Cp[0]"/>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <label for="Colonia">Colonia</label>
                            <input type="text" v-model="objSucursal.Colonia" maxlength="250" class="form-control" placeholder="Ingresar Colonia" />
                            <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*' + errorvalidacion.Colonia[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <label for="Cruzamiento1">Cruzamiento 1</label>
                            <input type="text" v-model="objSucursal.Cruzamiento1" maxlength="250" class="form-control" placeholder="Ingresar Cruzamiento 1" />
                            <CValidation v-if="this.errorvalidacion.Cruzamiento1" :Mensaje="'*' + errorvalidacion.Cruzamiento1[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <label for="Cruzamiento2">Cruzamiento 2</label>
                            <input type="text" v-model="objSucursal.Cruzamiento2" maxlength="250" class="form-control" placeholder="Ingresar Cruzamiento 2" />
                            <CValidation v-if="this.errorvalidacion.Cruzamiento2" :Mensaje="'*' + errorvalidacion.Cruzamiento2[0]"/>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
							<label for="item7">Estado</label>
                            <select v-model="objSucursal.IdEstado" class="form-control form-select" @change="getMunicipios()">
                                <option :value="0">--Seleccionar--</option>
                                <option v-for="(item, index) in estados" :key="index" :value="item.IdEstado">
                                    {{ item.Nombre }}
                                </option>
                            </select>
                            <CValidation v-if="this.errorvalidacion.IdEstado" :Mensaje="'*' +errorvalidacion.IdEstado[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label for="item7">Minicipio</label>
                            <select v-model="objSucursal.IdMunicipio" class="form-control form-select">
                                <option :value="0">--Seleccionar--</option>
                                <option v-for="(item, index) in municipios" :key="index" :value="item.IdMunicipio">
                                    {{ item.Nombre }}
                                </option>
                            </select>
                            <CValidation v-if="this.errorvalidacion.IdMunicipio" :Mensaje="'*' +errorvalidacion.IdMunicipio[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label for="Referencias">Referencias</label>
                            <textarea type="text" v-model="objSucursal.Referencias" maxlength="250" class="form-control" rows="3" placeholder="Ingresar Referencias"/>
                            <CValidation v-if="this.errorvalidacion.Referencias" :Mensaje="'*' +errorvalidacion.Referencias[0]"/>
                        </div>
                    </div>
                    <hr>
                    <div class="row form-group">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label>Clave de Sucursal</label>
                            <input type="text" v-model="objSucursal.ClaveSucursal" class="form-control" placeholder="Ingresar Clave de Identificación" />
                            <CValidation v-if="this.errorvalidacion.ClaveSucursal" :Mensaje="'*' +errorvalidacion.ClaveSucursal[0]"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label for="Referencias">Numero Folio Actual</label>
                            <input type="text" v-model="objSucursal.NumFolioPrestamo" @input="$onlyNums($event,objSucursal,'NumFolioPrestamo')" maxlength="10" class="form-control" placeholder="Ingresar Numero Serial de Folio" />
                            <CValidation v-if="this.errorvalidacion.NumFolioPrestamo" :Mensaje="'*' +errorvalidacion.NumFolioPrestamo[0]"/>
                        </div>
						<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<hr>
							<CMapa v-if="this.isReady" :pConfigMapa="ConfigMapa" :oLatLng="coordenadas" />
						</div>
                    </div>					
                </div>
			</form>
		</template>
	</CLoader>
</template>

<script>
    import CMapa from "../../../../components/CMapa";

	export default {
		name:  "FormSucursal",
		props: ["poBtnSave"],
		components: {
            CMapa,
        },
		data() {
			return {
                isReady: 				false,
				Emit: 					this.poBtnSave.EmitSeccion,
				estados:				[],
				municipios:				[],
				ListaEstadosArray: 		[],
				ListaMunicipiosArray: 	[],
				errorvalidacion: 		[],
                coordenadas: {
                    Lat: 20.9673271,
                    Lng: -89.6249853,
                },
                ConfigMapa:{
					ShowBuscador: false
                },
				ConfigLoad: {
					ShowLoader: true,
					ClassLoad:  false,
				},
				objSucursal: {
					IdSucursal: 	  0,
					IdEmpresa: 		  0,
					IdEstado: 		  '0',
					IdMunicipio: 	  '0',
					Nombre: 		  "",
					Calle: 	 		  "",
					NoInt: 			  0,
					NoExt: 			  0,
					Colonia: 	 	  "",
                    Cruzamiento1:     "",
                    Cruzamiento2:     "",
					Cp: 			  0,
					Referencias: 	  "",
					Telefono: 	 	  "",
					Latitud: 		  0,
					Longitud: 		  0,
					ClaveSucursal:    "",
					NumFolioPrestamo: ""
				},
				Empresa:{
					IdEmpresa: 		 0,
					NombreComercial: '',
					RazonSocial: 	 '',
					Rfc: 			 '', 
					Calle: 			 '',
					NoInt: 			 '',
					NoExt: 			 '',
					Colonia: 		 '',
                    Cruzamiento1:    '',
                    Cruzamiento2:    '',
					Cp: 			 '',
					Referencias: 	 '',
					IdEstado: 		 '0',
					IdMunicipio:     '0',
					Telefono: 		 ''
				},
			};
		},
		methods: {
			async Guardar() {
				this.errorvalidacion 	  = [];
				this.poBtnSave.toast 	  = 0;
				this.poBtnSave.DisableBtn = true;

				if (this.objSucursal.IdSucursal == 0) {
					this.$http.post("sucursales", this.objSucursal).then((res) => {
							this.EjecutaConExito(res);
						})
						.catch((err) => {
							this.EjecutaConError(err);
						});
				} else {
					this.$http.put("sucursales/" + this.objSucursal.IdSucursal,
						this.objSucursal
					)
					.then((res) => {
						this.EjecutaConExito(res);
					})
					.catch((err) => {
						this.EjecutaConError(err);
					});
				}
			},
			EjecutaConExito(res) {
				this.poBtnSave.DisableBtn = false;
				this.poBtnSave.toast 	  = 1;
				this.bus.$emit("CloseModal_" + this.Emit);
				this.bus.$emit("List_" + this.Emit);
			},
			EjecutaConError(err) {
				this.poBtnSave.DisableBtn = false;

				if (err.response.data.errors) {
					this.errorvalidacion = err.response.data.errors;
					this.poBtnSave.toast = 2;
				} else {
					this.$toast.error(err.response.data.message);
				}

			},
			Recuperar() {
				this.$http.get("sucursales/" + this.objSucursal.IdSucursal).then((res) => {
					this.objSucursal = res.data.data;
					this.getMunicipios();
                    this.setDefaultorCurrentLocation();
                    this.isReady = true;
				})
				.finally(() => {
					this.ConfigLoad.ShowLoader = false;
				});
			},
			Limpiar() {
				this.objSucursal = {
					IdSucursal: 	  0,
					IdEmpresa: 		  0,
					IdEstado: 		  '0',
					IdMunicipio: 	  '0',
					Nombre: 		  "",
					Calle: 	 		  "",
					NoInt: 			  0,
					NoExt: 			  0,
					Colonia: 	 	  "",
                    Cruzamiento1:     "",
                    Cruzamiento2:     "",
					Cp: 			  0,
					Referencias: 	  "",
					Telefono: 	 	  "",
					Latitud: 		  0,
					Longitud: 		  0,
					ClaveSucursal:    "",
					NumFolioPrestamo: ""
				};
				this.errorvalidacion = [];
                this.isReady 		 = false;
			},
            setDefaultorCurrentLocation() {
                this.coordenadas.Lng = parseFloat(this.objSucursal.Longitud);
                this.coordenadas.Lat = parseFloat(this.objSucursal.Latitud);
            },
			/*
			* Listado de estados del combo
			*/
			async getEstados() {
				await this.$http.get('estados').then(res => {
					this.estados = res.data.data;
				}).catch(err =>{
					this.estados = [];
				});
			},
			/*
			* Listado de municipios del combo
			*/
			getMunicipios() {
				if (parseInt(this.objSucursal.IdEstado) > 0) {
					this.municipios = [];

					this.$http.get('municipios',{
						params:{
							IdEstado: this.objSucursal.IdEstado
						}
					}).then(res => {
						this.municipios = res.data.data;
					}).catch(err =>{
						this.municipios = [];
					});

				} else {
					this.municipios = [];
				}
			},
			async recuperaEmpresa() {
				let usr = JSON.parse(sessionStorage.getItem('user'));

				await this.$http.get('empresas/'+usr.IdEmpresa).then(res => {
					this.Empresa = res.data.data;
					this.getMunicipios();
				}).catch(err =>{
					this.Limpiar();
				});
			},
		},
		created() {
			this.getEstados();
			this.recuperaEmpresa();
			this.poBtnSave.toast   = 0;
			this.ListaEstadosArray = JSON.parse(sessionStorage.getItem("ListaEstadosArray"));

			this.bus.$off("Recovery_" + this.Emit);
			this.bus.$on("Recovery_" + this.Emit, (Id) => {
				this.ConfigLoad.ShowLoader = true;
				this.poBtnSave.DisableBtn  = false;

				this.bus.$off("Save_" + this.Emit);
				this.bus.$on("Save_" + this.Emit, () => {
					let usr 				   = JSON.parse(sessionStorage.getItem('user'));
					this.objSucursal.IdEmpresa =  usr.IdEmpresa;
					this.Guardar();
				});
				this.Limpiar();

				if (Id != "") {
					this.objSucursal.IdSucursal = Id;
					this.Recuperar();
				} else {
					this.isReady 				 = true;
					this.ConfigLoad.ShowLoader   = false;
					this.ConfigMapa.ShowBuscador = true;
				}

			});
		},
        computed:{
            setLocation: function () {
                this.objSucursal.Longitud = this.coordenadas.Lng;
                this.objSucursal.Latitud  = this.coordenadas.Lat;
                return this.objSucursal;
            }
        }
	};

</script>
