<template>
    <ul class="navbar-nav h-100">
        <input type="hidden" :value="VerItems">
        <template v-for="(itemMenu,index) in pArrayMenus">
            <li v-if="VerificarMenuVisible(itemMenu.Permiso,itemMenu.EsDeRoot)" :key="index" :class="itemMenu.SubMenus.length>0?'nav-item dropdown':'nav-item'">
                <template v-if="itemMenu.TipoBoton==0">
                    <span v-if="itemMenu.SubMenus.length>0">
                        <a class="nav-link dropdown-toggle" href="#" :id="'dropdown'+index" data-toggle="dropdown" aria-expanded="false">
                            <i :class="itemMenu.Icono"></i>
                            <span>
                                {{itemMenu.Nombre}} <i class="fas fa-angle-down"></i>
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdown'+index">
                            <template v-for="(itemSubmenu,index2) in itemMenu.SubMenus">
                                <template v-if="itemSubmenu.TipoBoton==0">
                                    <router-link v-if="VerificaSubMenuVisible(itemSubmenu.Permiso,itemSubmenu.EsDeRoot)" :key="index2"  :to="{ name: itemSubmenu.Router, params: itemSubmenu.Parametros}" class="dropdown-item">
                                        {{itemSubmenu.Nombre}}
                                    </router-link>
                                </template>

                                <template v-else>
                                    <router-link v-if="VerificaSubMenuVisible(itemSubmenu.Permiso,itemSubmenu.EsDeRoot)" :key="index2"  :to="{ name: itemSubmenu.Router, params:{pListaSubMenus:itemSubmenu.Apartados, parametros:itemSubmenu.Parametros}}" class="dropdown-item">
                                        {{itemSubmenu.Nombre}}
                                    </router-link>
                                </template>
                            </template>
                        </div>
                    </span>

                    <span v-else>
                        <router-link :to="{ name: itemMenu.Router, params: itemMenu.Parametros}" class="nav-link">
                            <i :class="itemMenu.Icono"></i>
                            {{itemMenu.Nombre}}
                        </router-link>
                    </span>
                </template>

                <template v-else>
                    <router-link :to="{ name: itemMenu.Router, params: {pListaSubMenus:itemMenu.SubMenus, parametros:itemMenu.Parametros}}" class="nav-link">
                        <i :class="itemMenu.Icono"></i>
                        {{itemMenu.Nombre}}
                    </router-link>
                </template>
            </li>
        </template>
        <slot name="MenuDerecho"></slot>
    </ul>
</template>
<script>
import Template from '../views/template/Template.vue'
export default {
  components: { Template },
    props:['pArrayMenus','pMenus','pSubMenus','pVerMenu'],
    name:'htmlArregloMenus',
    data() {
        return {
            ListMenus: [],
			ListSubMenus: [],
			ListApartados: [],
			VerMenu: 0,
        }
    },
    methods:{
        VerificarMenuVisible(Clave,EsDeRoot)
		{
            let user = JSON.parse(sessionStorage.getItem("user"));
            if(EsDeRoot==1 && user.IdPerfil != 1){
                return false;
            }

			let Val = this.ListMenus.find( item => item.Clave == Clave);
			if(this.VerMenu>0){
				return true;
			}
			if (!Val) return false;

            return true;
		},
		VerificaSubMenuVisible(Clave,EsDeRoot)
		{
            let user = JSON.parse(sessionStorage.getItem("user"));
            if(EsDeRoot==1 && user.IdPerfil != 1){
                return false;
            }

			let Val = this.ListSubMenus.find( item => item.Clave == Clave);
			if(this.VerMenu>0){
				return true;
			}
			if (!Val) return false;

            return true;
		}
    },
    computed:{
        VerItems()
        {
            if (this.pMenus != undefined){
                this.ListMenus = this.pMenus;
            }
            if (this.pSubMenus != undefined){
                this.ListSubMenus = this.pSubMenus;
            }
            if (this.pVerMenu != undefined){
                this.VerMenu = this.pVerMenu;
            }

            return 1;
        }
    }
}
</script>
