import Inicio from "../views/modulos/inicio/List.vue";

const VarRutasInicio = [{
    path: '/Inicio',
    name: 'inicio',
    component: Inicio,
    props: true
}, ];

export default {
    VarRutasInicio,
}