<template>
    <div class="form-group">
        <label>Cliente</label>
       <!-- <input :value="checkId" type="hidden">
        <input :value="validate" type="hidden"> -->
        <v-select
                :value="value"
                @input="$emit('input', $event)"
                @search="onSearchClientes"
                :filterable="false"
                :reduce="item => item.key"
                :options="clientes"
                placeholder="Buscar cliente"
                :disabled="configInput.disabled"
        >

            <template slot="no-options">
                No hay clientes
            </template>

            <template slot="option" slot-scope="option">
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-1 col-lg-2 col-xl-1">
                        <i class="fa fa-user" ></i>
                    </div>
                    <div class="col-10 col-sm-10 col-md-11 col-lg-10 col-xl-8">
                        {{ option.label }}
                        <div class="row">
                            <div class="col-12"  >
                                {{ option.detalle.contacto }}
                            </div>
                        </div>
                    </div>

                </div>
            </template>

        </v-select>

        <slot></slot>

    </div>
</template>

<script>
export default {

    props: [
        'value',
        'pDisabled'
    ],

    data() {
        return {
            localValue: 0,
            form: this.value,

            clientes: [],
            debounce: null,
            configInput:{
                disabled: false,
            }
        }
    },

    methods: {

        onSearchClientes( search, loading ) {
            
            // PREVENTOR DE RE-BUSQUEDA EN BLANCO
            if(search != '' && search != undefined) { 
                this.clientes = [];

                clearTimeout( this.debounce );

                loading(true);

                this.debounce = setTimeout( async () => {

                    await this.getClientes( search, loading );

                }, 900);
            }
        },

        async getClientes( search, loading ) {

            const res = await this.$http.get( '/clientes', {
                params: {
                    TxtBusqueda: search,
                    items: 10,
                    page: 1
                }
            });

            loading(false);

            if ( res.status != 200 ) {

                this.clientes = [];

            };


            this.clientes = res.data.data.data.map( (item) => ({
                label: `${item.NombreComercial}`,
                detalle: {
                    contacto: item.Contacto,
                    correo: item.Correo,
                    telefono: item.Telefono,
                    RazonSocial: item.RazonSocial,
                    Rfc: item.Rfc,
                },
                key: item.IdCliente
            }) );

        },

    },
    computed:{
        /*checkId: function (){
            this.localValue = parseInt(this.value);
        },

        validate: function (){
            if(this.pDisabled != undefined){
                this.configInput.disabled = this.pDisabled
            }
        }*/
    }

}
</script>

<style>

</style>
