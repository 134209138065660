<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="header">
                <th>#</th>
				<th>Nombre</th>
                 <th>Clave</th>

                <th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista,index) in ListaArrayRows" :key="index" >
					<td>{{ (index+1) }}</td>
					<td>{{lista.Nombre}}</td>
                    <td>{{lista.Clave}}</td>

					<td>
						<CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdMetodoPago" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">
                            </template>
						</CBtnAccion>
					</td>
				</tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="4"></CSinRegistros>
			</template>
		</CList>

		<CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>
	</div>
</template>

<script>

import moment from 'moment';
import CList from "@/components/CList.vue";
import CModal from "@/components/CModal.vue";
import CBtnAccion from "@/components/CBtnAccion.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";

import Form from '@/views/catalogos/configfactura/metodopago/Form.vue';
import Configs from '@/helpers/VarConfig.js';
const EmitEjecuta = 'seccionMetodoPago';

export default {
    name: 'ListaMetodoPago',
    components: {
        Form,
        CList,
        CModal,
        CBtnAccion,
        CSinRegistros
    },
    data() {
        return {
            ConfigList:{
                Title: 'Listado Métodos de Pago',
                IsModal: true,
                /*ShowYear: true,*/
                ShowLoader: true,
                ShowSearch: true,
                ShowEntradas: true,
                EmitSeccion: EmitEjecuta,
                BtnReturnShow: true,
                TitleFirst: 'Catálogos de Configuración'
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                /*Anio: moment(new Date()).format('YYYY'),*/
            },
            ConfigModal:{
                ModalTitle: "Formulario Métodos de Pago",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta,
            },
            oBtnSave: {
                toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta,
            },
            segurity: {Add:1,Edit:1,Delete:1},
            obj: {},
            ListaArrayRows: [],
            ListaHeader: [],
        }
    },
    methods: {
		async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get("metodospago",{
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,
                }
            }).then((res) => {
                this.Filtro.Pagina      = res.data.data.current_page;
                this.Filtro.TotalItem   = res.data.data.total;
                this.ListaArrayRows     = res.data.data.data;
                //this.segurity           = res.data.segurity;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },
        Eliminar(Id)
        {
            this.$swal(Configs.configEliminar).then((result) =>
            {
                if(result.value)
                {
                    this.$http.delete('metodospago/'+Id
                    ).then((res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
        },
        Regresar() {
            this.$router.push({name:'panelSubMenus',params:{}});
        },
    },
    created()
    {
        this.bus.$off('Delete_'+EmitEjecuta);
        this.bus.$off('List_'+EmitEjecuta);
    },
    mounted()
    {
        this.Lista();
        this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List_'+EmitEjecuta,()=>
        {
            this.Lista();
        });
    }
}
</script>
