<template>
	<CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
			<form id="FormMoneda" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="IdEmpresa">IdEmpresa</label>
									<input type="text" v-model="objMoneda.IdEmpresa" class="form-control" id="IdEmpresa" placeholder="Ingresar IdEmpresa" />
									<Cvalidation v-if="this.errorvalidacion.IdEmpresa" :Mensaje="'*'+errorvalidacion.IdEmpresa[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Codigo">Codigo</label>
									<input type="text" v-model="objMoneda.Codigo" class="form-control" id="Codigo" placeholder="Ingresar Codigo" />
									<Cvalidation v-if="this.errorvalidacion.Codigo" :Mensaje="'*'+errorvalidacion.Codigo[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Nombre">Nombre</label>
									<input type="text" v-model="objMoneda.Nombre" class="form-control" id="Nombre" placeholder="Ingresar Nombre" />
									<Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Valor">Valor</label>
									<input type="text" v-model="objMoneda.Valor" class="form-control" id="Valor" placeholder="Ingresar Valor" />
									<Cvalidation v-if="this.errorvalidacion.Valor" :Mensaje="'*'+errorvalidacion.Valor[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="NumDecimales">No. Decimales</label>
									<input type="text" v-model="objMoneda.NumDecimales" class="form-control" id="NumDecimales" placeholder="Ingresar NumDecimales" />
									<Cvalidation v-if="this.errorvalidacion.NumDecimales" :Mensaje="'*'+errorvalidacion.NumDecimales[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="TipoMoneda">Tipo Moneda</label>
									<input type="text" v-model="objMoneda.TipoMoneda" class="form-control" id="TipoMoneda" placeholder="Ingresar TipoMoneda" />
									<Cvalidation v-if="this.errorvalidacion.TipoMoneda" :Mensaje="'*'+errorvalidacion.TipoMoneda[0]"/>
								</div>
							</div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="ReqIva">Requiere Iva</label>
                                    <b-form-checkbox v-model="objMoneda.ReqIva" name="check-button" :value="1" switch>
                                        <b v-if="objMoneda.ReqIva>0">SI</b>
                                        <b v-else>NO</b>
                                    </b-form-checkbox>
                                    <Cvalidation v-if="this.errorvalidacion.ReqIva" :Mensaje="'*'+errorvalidacion.ReqIva[0]"/>
                                </div>
                            </div>

							<br>
						</div><!--fin col-12-->
					</div>
				</div>
			</form>
		</template>
	</CLoader>
</template>

<script>

export default {
    name: 'FormMoneda',
    props: ['poBtnSave'],
    components:{
    },
    data() {
        return {
			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objMoneda: {
				IdMoneda: 0,
				IdEmpresa: '',
				Codigo: '',
				Nombre: '',
				Valor: 0,
				NumDecimales: 0,
				TipoMoneda: '',
				ReqIva: '',

            },
            errorvalidacion: [],
			Emit: this.poBtnSave.EmitSeccion,
			masks:{ input: 'YYYY-MM-DD'}, // quitar sino lleva fecha
        }
    },
    methods :
    {
       	async Guardar()
        {
			this.errorvalidacion = [];
            this.poBtnSave.toast = 0;
            this.poBtnSave.DisableBtn = true;

			if(this.objMoneda.IdMoneda == 0) {
				await this.$http.post(
					'moneda',this.objMoneda
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'moneda/'+this.objMoneda.IdMoneda,
					this.objMoneda
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
		Recuperar()
        {
            this.$http.get(
                "moneda/"+this.objMoneda.IdMoneda
            ).then((res) => {
                this.objMoneda = res.data.data;

				// en caso de que necesite fecha
				/*let formatedDate1 = this.objMoneda.Fecha.replace(/-/g,'\/');
                this.objMoneda.Fecha = new Date(formatedDate1);*/
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objMoneda = {
				IdMoneda: 0,
				IdEmpresa: '',
				Codigo: '',
				Nombre: '',
				Valor: 0,
				NumDecimales: 0,
				TipoMoneda: '',
				ReqIva: '',

            }
            this.errorvalidacion = [];
        },
    },
    created() {
		this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();

            if (Id!='')
            {
                this.objMoneda.IdMoneda = Id;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    }
}
</script>
