<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">

            <template slot="header">
                <th class="td-sm"></th>
                <th>Folio</th>
                <th>Sucursal</th>
                <th>Total</th>

                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{lista.FolioRemision }}</td>
                    <td>{{lista.clientesucursal.Nombre}}</td>
                    <td>{{$formatNumber(lista.Total,'$')}}</td>

                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pId="lista.IdPedido" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                            <template slot="btnaccion">
                                <button @click="verRemision(lista)" type="button" v-b-tooltip.hover.Top title="Ver Remisión" class="btn btn-icon btn-primary ml-1">
                                    <i class="fa fa-file-pdf"></i>
                                </button>
                            </template>
                        </CBtnAccion>
                    </td>
                </tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistros>
            </template>
        </CList>
    </div>
</template>
<script>
import CList from "@/components/CList.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";
import CBtnAccion from "@/components/CBtnAccion.vue";
const EmitEjecuta      =    'seccionNotasRemision';

export default {
    name: "viewNotasRemisiones",
    props: ['sFiltros','pObjOrdenProduccion'],
    components: {
        CList,
        CSinRegistros,
        CBtnAccion
    },

    data() {
        return {
            localOrdenProduccion:{
                IdOrdenProduccion: 0
            },

            ConfigList:{
                Title:         'Notas de Remisión',
                TitleFirst:    'Ordenes de Producción',
                IsModal:       true,
                ShowLoader:    true,
                BtnReturnShow: true,
                BtnNewShow:    false,
                EmitSeccion:   EmitEjecuta,
            },
            Filtro:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: 'Buscar..',
                TipoBitacora: '0',
            },

            segurity:       {Add:1,Edit:1,Delete:1},
            obj:            {},
            ListaArrayRows: [],
            ListaHeader:    []
        }
    },
    methods: {
        async Lista() {
            this.ConfigList.ShowLoader = true;

            await this.$http.get("viewnotasremision", {
                params: {
                    TxtBusqueda:        this.Filtro.Nombre,
                    Entrada: 	        this.Filtro.Entrada,
                    Pag: 		        this.Filtro.Pagina,
                    IdOrdenProduccion:  this.localOrdenProduccion.IdOrdenProduccion
                },
            })
                .then((res) => {
                    this.Filtro.Pagina    = res.data.data.current_page;
                    this.Filtro.TotalItem = res.data.data.total;
                    this.ListaArrayRows   = res.data.data.data;
                    //this.segurity         = res.data.segurity;
                })
                .finally(() => {
                    this.ConfigList.ShowLoader = false;
                    this.$saveFilters('SET');
                });
        },

        Regresar() {
            this.$router.push({name:'ordenesproduccion',params:{}});
        },

        verRemision(lista){
            let urlPdf = `${this.$http.defaults.baseURL}/getpdfnotaremision/${lista.IdPedido}`;
            window.open(urlPdf,'_blank');
        },
    },
    computed: {

    },
    created() {
        if(this.sFiltros===undefined) {
            this.$saveFilters('GET');
        }

        if(this.pObjOrdenProduccion !== undefined){
            this.localOrdenProduccion = this.pObjOrdenProduccion;
        }

        this.bus.$off('List_'+EmitEjecuta);
        this.bus.$off('EmitReturn');
    },

    mounted() {
        this.Lista();
        this.bus.$on('List_'+EmitEjecuta,()=> {
            this.Lista();
        });

        this.bus.$on('EmitReturn',()=> {
            this.Regresar();
        });
    }

}
</script>
