<template>
	<CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
			<form id="FormFormaPago" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Nombre">Nombre</label>
									<input type="text" v-model="objFormaPago.Nombre" class="form-control" id="Nombre" placeholder="Ingresar Nombre" />
									<Cvalidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Clave">Clave</label>
									<input type="text" v-model="objFormaPago.Clave" class="form-control" id="Clave" placeholder="Ingresar Clave" />
									<Cvalidation v-if="this.errorvalidacion.Clave" :Mensaje="'*'+errorvalidacion.Clave[0]"/>
								</div>
							</div>

							<br>
						</div><!--fin col-12-->
					</div>
				</div>
			</form>
		</template>
	</CLoader>
</template>

<script>

export default {
    name: 'FormFormaPago',
    props: ['poBtnSave'],
    components:{
    },
    data() {
        return {
			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objFormaPago: {
				IdFormaPago: 0,
				Nombre: '',
				Clave: '',

            },
            errorvalidacion: [],
			Emit: this.poBtnSave.EmitSeccion,
			masks:{ input: 'YYYY-MM-DD'}, // quitar sino lleva fecha
        }
    },
    methods :
    {
       	async Guardar()
        {
			this.errorvalidacion = [];
            this.poBtnSave.toast = 0;
            this.poBtnSave.DisableBtn = true;

			if(this.objFormaPago.IdFormaPago == 0) {
				await this.$http.post(
					'formaspagos',this.objFormaPago
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'formaspagos/'+this.objFormaPago.IdFormaPago,
					this.objFormaPago
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
		Recuperar()
        {
            this.$http.get(
                "formaspagos/"+this.objFormaPago.IdFormaPago
            ).then((res) => {
                this.objFormaPago = res.data.data;

				// en caso de que necesite fecha
				/*let formatedDate1 = this.objFormaPago.Fecha.replace(/-/g,'\/');
                this.objFormaPago.Fecha = new Date(formatedDate1);*/
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objFormaPago = {
				IdFormaPago: 0,
				Nombre: '',
				Clave: '',

            }
            this.errorvalidacion = [];
        },
    },
    created() {
		this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();

            if (Id!='')
            {
                this.objFormaPago.IdFormaPago = Id;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    }
}
</script>
