
import OrdenesProduccion 	from "../views/modulos/produccion/ordenesproduccion/List.vue";
import FormOrdenProduccion 	from "@/views/modulos/produccion/ordenesproduccion/FormOrdenProduccion.vue";
import OrdenesRecolecciones from '../views/modulos/produccion/recolecciones/List.vue'
import FormRecoleccion 		from "@/views/modulos/produccion/recolecciones/FormRecoleccion.vue";
import Pedidos 				from "@/views/modulos/produccion/pedidos/List.vue";
import FormPedidos 			from "@/views/modulos/produccion/pedidos/FormPedidos.vue";
import viewNotasRemision from "@/views/modulos/produccion/ordenesproduccion/viewNotasRemision.vue";

const VarRutasProduccion = [
	{
		path: "/pedidos",
		name: "pedidos",
		component: Pedidos,
		props: true,
	},
	{
		path: "/formulariopedidos",
		name: "formulariopedidos",
		component: FormPedidos,
		props: true,
	},
	{
		path: "/ordenesproduccion",
		name: "ordenesproduccion",
		component: OrdenesProduccion,
		props: true,
	},
	{
		path: "/formulariordenesproduccion",
		name: "formulariordenesproduccion",
		component: FormOrdenProduccion,
		props: true,
	},
	{
		path: "/recolecciones",
		name: "recolecciones",
		component: OrdenesRecolecciones,
		props: true,
	},
	{
		path: "/formrecoleccion",
		name: "formrecoleccion",
		component: FormRecoleccion,
		props: true,
	},
	{
		path: "/viewnotasremisiones",
		name: "viewnotasremisiones",
		component: viewNotasRemision,
		props: true,
	},



];

export default {
	VarRutasProduccion,
};
