import PanelSubMenus   from "../views/template/SubMenus.vue";
import Perfiles        from "../views/catalogos/perfiles/List.vue";
import Vehiculos       from "../views/catalogos/vehiculos/List.vue";
import Puestos         from "../views/catalogos/puestos/List.vue";
import Estados         from "../views/catalogos/estados/List.vue";
import Municipios      from "../views/catalogos/municipios/List.vue";
import Permisos        from "../views/catalogos/permisos/List.vue";
import Paneles         from "../views/catalogos/paneles/List.vue";
import Correo          from "../views/catalogos/correo/Form.vue";
import Negocios        from "../views/catalogos/negocios/List.vue";
import Productos       from "../views/catalogos/productos/List.vue";
import Proveedores     from '../views/catalogos/proveedores/List.vue';
import AsigPermisos    from "../views/catalogos/menusxPerfil/AsignarList.vue";


const VarRutasConfiguracion = [{
        path: '/PanelDetalle',
        name: 'panelSubMenus',
        component: PanelSubMenus,
        props: true
    },
    {
        path: '/Perfiles',
        name: 'perfiles',
        component: Perfiles,
        props: true
    },
    {
        path: '/Puestos',
        name: 'puestos',
        component: Puestos,
        props: true
    },
    {
        path: '/Paneles',
        name: 'paneles',
        component: Paneles,
        props: true
    },
    {
        path: '/Estados',
        name: 'estados',
        component: Estados,
        props: true
    },
    {
        path: '/Municipios',
        name: 'municipios',
        component: Municipios,
        props: true
    },
    {
        path: '/Permisos',
        name: 'permisos',
        component: Permisos,
        props: false
    },
    {
        path: '/AsignarPermiso',
        name: 'asignarPermisos',
        component: AsigPermisos,
        props: true
    },

    {
        path: '/ConfiguracionCorreoElectronico',
        name: 'correo',
        component: Correo,
        props: true
    },
    {
        path: '/TipodeNegocios',
        name: 'negocios',
        component: Negocios,
        props: true
    },
    {
        path: '/productos',
        name: 'productos',
        component: Productos,
        props: true
    },
    {
        path: '/vehiculos',
        name: 'vehiculos',
        component: Vehiculos,
        props: true
    },
    {
        path: '/proveedores',
        name: 'proveedores',
        component: Proveedores,
        props: true
    },


];

export default {
    VarRutasConfiguracion,
}
