<template>
    <CTabla @FiltrarC="lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
        <template slot="header">
            <th class="td-sm"></th>
            <th>Producto</th>
            <th>Precio</th>

            <th class="text-center">Acciones</th>
        </template>

        <template slot="body">
            <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                <td class="td-sm"></td>
                <td>{{lista.Producto}}</td>
                <td>{{lista.PrecioBase}}</td>

                <td class="text-center">
                    <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                        <template slot="btnaccion">
                            <button type="button" v-b-tooltip.hover.Top title="Agregar" class="btn btn-primary btn-icon" @click="asignarProducto(lista)">
                                <i class="fas fa-plus"></i>
                            </button>

                        </template>
                    </CBtnAccion>
                </td>
            </tr>
            <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistros>
        </template>
    </CTabla>
</template>
<script>
import CTabla from "@/components/CTabla.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";

const EmitEjecuta = 'seccionSelectProductos';

export default {
    name: "ListaSelectProductos",
    props: ['poBtnSave'],
    components: {
        CTabla,
        CSinRegistros
    },

    data() {
        return {
            Emit:               this.poBtnSave.EmitSeccion,
            errorvalidacion:    [],
            ListaArrayRows:     [],
            ListaHeader:        [],
            objEquipos:         {},
            segurity:           {},
            obj:                {},
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            ConfigList:{
                Title: 'Listado de Productos',
                ShowLoader: false,
                BtnNewShow: false,
                ShowTitleFirst: false,
                EmitSeccion:   EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            objCliente:{
                IdCliente:0
            }
        }
    },
    methods: {
        async lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('productos', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                }
            }).then( (res) => {
                this.ListaArrayRows   = res.data.data.data;
                this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total;

            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },

        asignarProducto(item) {
            let nameShowed = '';
            nameShowed = item.Producto

            let obj = {
                IdProducto: item.IdProducto,
                Producto:   nameShowed,
                PrecioBase: item.PrecioBase,
                ConIva:     item.ConIva
            }

            this.bus.$emit('pAsignarProducto',obj);
            this.bus.$emit('CloseModal_'+this.Emit);
        },
    },
    computed: {},
    created() {
        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            this.objCliente.IdCliente = obj
            this.lista();
        });
    }

}
</script>
