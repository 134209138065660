<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="Nombre">Usuario</label>
                                    <input type="text" name="item16" id="item16" class="form-control" placeholder="Nombre del Usuario" v-model="objUsuarioEmpleado.username" autocomplete="off" readonly>
                                    <CValidation v-if="this.errorvalidacion.username" :Mensaje="'*'+errorvalidacion.username[0]"/>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <label for="item18">Contraseña</label>
                                    <input v-model="objUsuarioEmpleado.password" :type="type1" name="item18" id="item18" class="form-control" placeholder="Contraseña" autocomplete="off">
                                    <button @click="ToggleShow" class="button btn-password-formulario" type="button" id="button-addon1">
                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                    </button>
                                    <CValidation v-if="this.errorvalidacion.password" :Mensaje="'*'+errorvalidacion.password[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <label for="item19">Confirmar Contraseña</label>
                                    <input v-model="objUsuarioEmpleado.password_confirmation" :type="type2" name="item19" id="item19" class="form-control" placeholder="Confirmar Contraseña" autocomplete="off">
                                    <button @click="ToggleShowConfirm" class="button btn-password-formulario" type="button" id="button-addon2" >
                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPasswordConfirm, 'fa-eye': !showPasswordConfirm }"></i>
                                    </button>
                                    <CValidation v-if="this.errorvalidacion.password_confirmation" :Mensaje="'*'+errorvalidacion.password_confirmation[0]"/>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <label for="item17">Perfil <span class="text-danger">*</span></label>
                                    <select v-model="objUsuarioEmpleado.IdPerfil" name="item17" id="item17" class="form-control form-select">
                                        <option value="0">--Seleccionar--</option>
                                        <option  v-for="(item, index) in ListaDePerfiles" :key="index" :value="item.IdPerfil" >{{item.Nombre}}</option>
                                    </select>
                                    <CValidation v-if="this.errorvalidacion.IdPerfil" :Mensaje="'*'+errorvalidacion.IdPerfil[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <label for="Nombre">Sucursal</label>
                                    <select id="IdSucursal" v-model="objUsuarioEmpleado.IdSucursal" class="form-control form-select" >
                                        <option value="0">--Seleccionar--</option>
                                        <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                            {{ item.Nombre }}
                                        </option>
                                    </select>
                                    <label>
                                        <CValidation v-if="this.errorvalidacion.IdSucursal" :Mensaje="'*' + errorvalidacion.IdSucursal[0]"></CValidation>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="item20">Accesos</label>
                                    <b-form-checkbox class="mt-1" v-model="checked" name="check-button" id="item20" switch>
                                        APP
                                    </b-form-checkbox>
                                </div>
                            </div>



                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

export default {
    name:  "FormPassword",
    props: ['poBtnSave'],
    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objUsuarioEmpleado:{
                IdEmpleado:0,
                IdUsuario:0,
                IdSucursal: 0,
                IdPerfil: 0,
                username: '',
                password: '',
                password_confirmation: '',
                UsuarioApp: 0,
            },
            Emit: this.poBtnSave.EmitSeccion,
            errorvalidacion: [],
            ListaSucursalesArray: [],
            ListaDePerfiles:[],
            type1: 'password',
            type2: 'password',
            showPassword: false,
            showPasswordConfirm: false,
            checked: false,
        }
    },
    methods: {
        async Guardar()
        {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0;
            this.poBtnSave.DisableBtn = true;

            if(this.checked==true) {
                this.objUsuarioEmpleado.UsuarioApp = 1;
            }
            else
            {
                this.objUsuarioEmpleado.UsuarioApp = 0;

            }

            this.$http.post(
                'usersPasswordEmpleado', this.objUsuarioEmpleado
            ).then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_seccionEmpleadosList');
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar()
        {
            this.$http.get(
                "users/getUsuEmple/"+this.objUsuarioEmpleado.IdUsuario
            ).then((res) => {
                let resp =  res.data.data;
                this.objUsuarioEmpleado.IdEmpleado  = resp.IdEmpleado;
                this.objUsuarioEmpleado.IdUsuario   = resp.IdUsuario;
                this.objUsuarioEmpleado.username    = resp.username;
                this.objUsuarioEmpleado.IdPerfil    = resp.IdPerfil;
                this.objUsuarioEmpleado.IdSucursal  = resp.IdSucursal;

                if (resp.UsuarioApp === 0) {
                    this.checked = false
                } else {
                    this.checked = true
                }
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objUsuarioEmpleado = {
                IdEmpleado:0,
                IdUsuario:0,
                IdSucursal: 0,
                IdPerfil: 0,
                username: '',
                password: '',
                password_confirmation: '',
                UsuarioApp: 0,
            };
            this.errorvalidacion = [];
        },
        async ListaPerfiles()
        {
            await this.$http.get('perfiles', {
                params:{
                    simple: 1
                }
            }).then( (res) => {
                this.ListaDePerfiles = res.data.data.perfiles;
            });
        },
        ListaSucursales()
        {
            this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
            .then((res) => {
                this.ListaSucursalesArray = res.data.data;
            });
        },
        ToggleShow()
        {
            if (this.showPassword = !this.showPassword) {
                this.type1 = 'text'
            } else {
                this.type1 = 'password'
            }
        },
        ToggleShowConfirm()
        {
            if (this.showPasswordConfirm = !this.showPasswordConfirm) {
                this.type2 = 'text'
            } else {
                this.type2 = 'password'
            }
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=> {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });
            this.ListaSucursales();
            this.ListaPerfiles();
            this.Limpiar();

            this.objUsuarioEmpleado.IdUsuario = obj.IdUsuario;
            this.Recuperar();
        });
    },
}
</script>
