<template>
    <div>
        <CLoader :pConfigLoad="ConfigLoad">
            <template slot="BodyFormLoad">
                <form id="Formproductos" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                                <div class="form-group row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label for="Nombre">Nombre</label>
                                        <input type="text" v-model="objProducto.Producto"  class="form-control" placeholder="Ingresar Nombre Producto" />
                                        <label v-if="this.errorvalidacion.Producto"><CValidation :Mensaje="'*'+errorvalidacion.Producto[0]"></CValidation></label>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label for="Nombre">Código</label>
                                        <input type="text" v-model="objProducto.Codigo"  class="form-control" placeholder="Ingresar Codigo del Producto" />
                                        <label v-if="this.errorvalidacion.Codigo"><CValidation :Mensaje="'*'+errorvalidacion.Codigo[0]"></CValidation></label>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label for="Nombre">Clave Producto/Servicio SAT</label>
                                        <input type="text" v-model="objProducto.ClaveProdServ"  class="form-control" placeholder="Ingresar Clave" />
                                        <label v-if="this.errorvalidacion.ClaveProdServ"><CValidation :Mensaje="'*'+errorvalidacion.ClaveProdServ[0]"></CValidation></label>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label for="Nombre">Clave de Unidad</label>
                                        <input type="text" v-model="objProducto.ClaveUnidad"  class="form-control" placeholder="Ingresar Clave de Unidad" />
                                        <label v-if="this.errorvalidacion.ClaveUnidad"><CValidation :Mensaje="'*'+errorvalidacion.ClaveUnidad[0]"></CValidation></label>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <label for="Nombre">Precio Base</label>
                                        <input type="text" @input="$number_decimal($event,objProducto,'PrecioBase')" v-model="objProducto.PrecioBase" class="form-control" placeholder="0.00" />
                                        <label v-if="this.errorvalidacion.PrecioBase"><CValidation :Mensaje="'*'+errorvalidacion.PrecioBase[0]"></CValidation></label>
                                    </div>
                                </div>
                                <div class="form-group row mt-2">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="form-check">
                                            <input v-model="objProducto.ConIva" type="checkbox" class="form-check-input" id="exampleCheck1">
                                            <label class="form-check-label" for="exampleCheck1">¿Este Producto lleva IVA?</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </CLoader>
    </div>
</template>
<script>
import CLoader from "@/components/CLoader.vue";
import CValidation from "@/components/CValidation.vue";
export default {
    name: "FormularioProductos",
    props: ['poBtnSave'],
    components: {
        CLoader,
        CValidation
    },

    data() {
        return {
            errorvalidacion:[],
            Emit:            this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objProducto: {
                IdProducto: 0,
                Producto: '',
                Codigo: '',
                ClaveProdServ: '',
                ClaveUnidad: '',
                PrecioBase: 0,
                ConIva: true,
            }
        }
    },
    methods: {
        async Guardar() {
            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0;
            this.poBtnSave.DisableBtn = true;

            //if(this.objEstados.IdEstado == 0) {
                this.$http.post('productos',this.objProducto).then((res)=>{
                    this.EjecutaConExito(res);
                }).catch(err=>{
                    this.EjecutaConError(err);
                });
            /*} else {
                this.$http.put(
                    'productos/'+this.objEstados.IdEstado,
                    this.objEstados
                ).then((res)=>{
                    this.EjecutaConExito(res);
                }).catch(err=>{
                    this.EjecutaConError(err);
                });
            }*/

        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },

        Recuperar(){
            this.$http.get("productos/"+this.objProducto.IdProducto)
                .then( (res) => {
                    this.objProducto = res.data.data;
                    this.objProducto.ConIva       = parseInt(this.objProducto.ConIva) > 0 ? true : false;

                }).finally(()=>{

                    this.ConfigLoad.ShowLoader = false;
                });
        },

        Limpiar() {
            this.objProducto = {
                IdProducto: 0,
                Producto: '',
                Codigo: '',
                ClaveProdServ: '',
                ClaveUnidad: '',
                PrecioBase: 0,
                ConIva: true,
            }
            this.errorvalidacion = [];
        },
    },
    computed: {},
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id) => {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,() => {
                this.Guardar();
            });

            this.Limpiar();

            if(Id!='') {
                this.objProducto.IdProducto = Id;
                this.Recuperar();

            } else {
                this.ConfigLoad.ShowLoader = false;
            }

        });
    }

}
</script>
