<template>
	<CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
			<form id="FormLlavesTimbrado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="IdEmpresa">IdEmpresa</label>
									<input type="text" v-model="objLlavesTimbrado.IdEmpresa" class="form-control" id="IdEmpresa" placeholder="Ingresar IdEmpresa" />
									<Cvalidation v-if="this.errorvalidacion.IdEmpresa" :Mensaje="'*'+errorvalidacion.IdEmpresa[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="NumCertificado">NumCertificado</label>
									<input type="text" v-model="objLlavesTimbrado.NumCertificado" class="form-control" id="NumCertificado" placeholder="Ingresar NumCertificado" />
									<Cvalidation v-if="this.errorvalidacion.NumCertificado" :Mensaje="'*'+errorvalidacion.NumCertificado[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Certificado">Certificado</label>
									<input type="text" v-model="objLlavesTimbrado.Certificado" class="form-control" id="Certificado" placeholder="Ingresar Certificado" />
									<Cvalidation v-if="this.errorvalidacion.Certificado" :Mensaje="'*'+errorvalidacion.Certificado[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="LlaveCancelacion">LlaveCancelacion</label>
                                    <textarea v-model="objLlavesTimbrado.LlaveCancelacion" class="form-control" id="LlaveCancelacion" placeholder="Ingresar LlaveCancelacion" rows="3">
                                    </textarea>
									<Cvalidation v-if="this.errorvalidacion.LlaveCancelacion" :Mensaje="'*'+errorvalidacion.LlaveCancelacion[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="ArchivoCer">ArchivoCer</label>
									<input type="text" v-model="objLlavesTimbrado.ArchivoCer" class="form-control" id="ArchivoCer" placeholder="Ingresar ArchivoCer" />
									<Cvalidation v-if="this.errorvalidacion.ArchivoCer" :Mensaje="'*'+errorvalidacion.ArchivoCer[0]"/>
								</div>
							</div>

							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="ArchivoKey">ArchivoKey</label>
									<input type="text" v-model="objLlavesTimbrado.ArchivoKey" class="form-control" id="ArchivoKey" placeholder="Ingresar ArchivoKey" />
									<Cvalidation v-if="this.errorvalidacion.ArchivoKey" :Mensaje="'*'+errorvalidacion.ArchivoKey[0]"/>
								</div>
							</div>

							<br>
						</div><!--fin col-12-->
					</div>
				</div>
			</form>
		</template>
	</CLoader>
</template>

<script>

export default {
    name: 'FormLlavesTimbrado',
    props: ['poBtnSave'],
    components:{
    },
    data() {
        return {
			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objLlavesTimbrado: {
				IdLlaveTimbrado: 0,
				IdEmpresa: '',
				NumCertificado: '',
				Certificado: '',
				LlaveCancelacion: '',
				ArchivoCer: '',
				ArchivoKey: '',

            },
            errorvalidacion: [],
			Emit: this.poBtnSave.EmitSeccion,
			masks:{ input: 'YYYY-MM-DD'}, // quitar sino lleva fecha
        }
    },
    methods :
    {
       	async Guardar()
        {
			this.errorvalidacion = [];
            this.poBtnSave.toast = 0;
            this.poBtnSave.DisableBtn = true;

			if(this.objLlavesTimbrado.IdLlaveTimbrado == 0) {
				await this.$http.post(
					'LlavesTimbrado',this.objLlavesTimbrado
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'LlavesTimbrado/'+this.objLlavesTimbrado.IdLlaveTimbrado,
					this.objLlavesTimbrado
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
		Recuperar()
        {
            this.$http.get(
                "LlavesTimbrado/"+this.objLlavesTimbrado.IdLlaveTimbrado
            ).then((res) => {
                this.objLlavesTimbrado = res.data.data;

				// en caso de que necesite fecha
				/*let formatedDate1 = this.objLlavesTimbrado.Fecha.replace(/-/g,'\/');
                this.objLlavesTimbrado.Fecha = new Date(formatedDate1);*/
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objLlavesTimbrado = {
				IdLlaveTimbrado: 0,
				IdEmpresa: '',
				NumCertificado: '',
				Certificado: '',
				LlaveCancelacion: '',
				ArchivoCer: '',
				ArchivoKey: '',

            }
            this.errorvalidacion = [];
        },
    },
    created() {
		this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();

            if (Id!='')
            {
                this.objLlavesTimbrado.IdLlaveTimbrado = Id;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    }
}
</script>
