<template>
    <div>
        <CList :pConfigList="ConfigList" >
            <template slot="bodyForm">
                <input type="hidden" :value="setLocation">

                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    General
                                </a>
                            </li>
                            <li class="nav-item" role="presentation" v-if="parseInt(this.objCliente.IdCliente) > 0">
                                <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false" >
                                    Sucursales
                                </a>
                            </li>
                            <!--<li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab4-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">
                                    Control Comercial
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content shadow-sm" id="myTabContent">
                            <!-- Parate Uno General -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Datos Generales&nbsp;</legend>
                                                <div class="form-row mt-4">
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                        <div class="avatar-upload">
                                                            <div class="avatar-edit">
                                                                <input id="file" @change="$uploadImagePreview($event,ValidElement, Array('Img','imagePreview'))" ref="file" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                                <label for="file"></label>
                                                            </div>
                                                            <div class="avatar-edit2" v-if="this.objCliente.UrlImg !== 'picture.png'">
                                                                <button type="button" @click="previewCliente(RutaFile,objCliente.UrlImg)">
                                                                    <i class="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                            <div class="avatar-preview">
                                                                <div id="imagePreview" :style="'background-image: url('+RutaFile+objCliente.UrlImg+');'" :src="RutaFile+objCliente.UrlImg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
                                                        <!-- <legend class="col-form-label">Datos personales</legend> -->
                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="NombreComercial">Nombre Comercial <span class="text-danger">*</span> </label>
                                                                <b-form-input id="NombreComercial" v-model="objCliente.NombreComercial" type="text" placeholder="Ingrese Nombre Comercial" ></b-form-input>
                                                                <CValidation v-if="this.errorvalidacion.NombreComercial" :Mensaje="'*' + errorvalidacion.NombreComercial[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="RazonSocial">Razon Social <span class="text-danger">*</span></label>
                                                                <b-form-input id="RazonSocial" v-model="objCliente.RazonSocial" type="text" placeholder="Ingrese Razon Social" ></b-form-input>
                                                                <CValidation v-if="this.errorvalidacion.RazonSocial" :Mensaje="'*' + errorvalidacion.RazonSocial[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Rfc">RFC</label>
                                                                <input class="form-control" id="Rfc" v-model="objCliente.Rfc" type="text" placeholder="Ingrese un RFC"/>
                                                                <CValidation v-if="this.errorvalidacion.Rfc" :Mensaje="'*' + errorvalidacion.Rfc[0]"/>

                                                            </div>
                                                        </div>

                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="NumCliente">Numero Cliente</label>
                                                                <input class="form-control" id="Correo" v-model="objCliente.NumCliente" type="text" placeholder="" disabled/>
                                                                <CValidation v-if="this.errorvalidacion.NumCliente" :Mensaje="'*' + errorvalidacion.NumCliente[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                                <label for="TipoNegocio">Tipo de Negocio <span class="text-danger">*</span></label>
                                                                <treeselect v-model="objCliente.TipoNegocio" :options="Negocio" :multiple="false" :show-count="true" :value-consists-of="'ALL_WITH_INDETERMINATE'" placeholder="Seleccione un Tipo de Negocio"/>
                                                                <CValidation v-if="this.errorvalidacion.TipoNegocio" :Mensaje="'*' + errorvalidacion.TipoNegocio[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" v-if="objCliente.TipoNegocio == 1">
                                                                <label for="NuevoNegocio">Nuevo Negocio</label>
                                                                <input v-model="objCliente.Negocio" type="text" placeholder="Ingrese Nuevo Negocio" class="form-control" />
                                                                <CValidation v-if="this.errorvalidacion.Negocio" :Mensaje="'*' + errorvalidacion.Negocio[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <div class="form-check checkbox-lg ml-2 mt-3">
                                                                        <input v-model="objCliente.CompraConIva" type="checkbox" class="form-check-input" id="exampleCheck1">
                                                                        <label class="form-check-label" for="exampleCheck1">¿El Cliente Compra Con IVA?</label>
                                                                    </div>
                                                            </div>
                                                        </div>

                                                    

                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                    </div>
                                                </div>
                                            </fieldset>

											<fieldset class="mt-2">
                                                <legend class="col-form-label">&nbsp;Contacto&nbsp;</legend>
                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                        <label for="Contacto">Nombre </label>
                                                        <input  v-model="objCliente.Contacto" type="text" placeholder="Ingrese Nombre del Contacto" class="form-control" />
                                                        <CValidation v-if="this.errorvalidacion.Contacto" :Mensaje="'*' + errorvalidacion.Contacto[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                        <label for="Telefono">Teléfono <span class="text-danger">*</span></label>
                                                        <input
                                                                id="Telefono"
                                                                v-model="objCliente.Telefono"
                                                                type="text"
                                                                placeholder="Ingrese Teléfono"
                                                                @input="$onlyNums($event,objCliente,'Telefono');"
                                                                class="form-control"/>
                                                        <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*' + errorvalidacion.Telefono[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                        <label for="CorreoContacto">Correo</label>
                                                        <b-form-input id="Correo" v-model="objCliente.Correo" type="email" placeholder="Ingrese Correo " ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*' + errorvalidacion.Correo[0]"/>
                                                    </div>

                                                </div>

                                            </fieldset>

                                            <fieldset class="mt-2">
                                                <legend class="col-form-label">&nbsp;Domicilio&nbsp;</legend>
                                                <small>{{objCliente.Direccion}}</small>
                                                <div class="form-group form-row">

                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <label for="Calle">Calle <span class="text-danger">*</span></label>
                                                        <b-form-input id="Calle" v-model="objCliente.Calle" type="text" placeholder="Ingrese Calle" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*' + errorvalidacion.Calle[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <label for="NoExt">Número Exterior <span class="text-danger">*</span></label>
                                                        <b-form-input id="NoExt" v-model="objCliente.NoExt" type="text" placeholder="Ingrese Número Exterior" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*' + errorvalidacion.NoExt[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <label for="NoInt">Número Interior</label>
                                                        <b-form-input id="NoInt" v-model="objCliente.NoInt" type="text" placeholder="Ingrese Número Interior" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*' + errorvalidacion.NoInt[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <label for="Cp">Código Postal <span class="text-danger">*</span></label>
                                                        <input id="Cp" v-model="objCliente.Cp" type="text" placeholder="Ingrese Código Postal" @input="$onlyNums($event,objCliente,'Cp');" class="form-control" />
                                                        <CValidation v-if="this.errorvalidacion.Cp" :Mensaje="'*' + errorvalidacion.Cp[0]"/>
                                                    </div>
                                                </div>

                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Cruzamiento1">Cruzamiento 1 <span class="text-danger">*</span></label>
                                                        <b-form-input id="Cruzamiento1" v-model="objCliente.Cruzamiento1" placeholder="Ingrese Cruzamiento1"> </b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Cruzamiento1" :Mensaje="'*' + errorvalidacion.Cruzamiento1[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Cruzamiento2">Cruzamiento 2 <span class="text-danger">*</span></label>
                                                        <b-form-input id="Cruzamiento2" v-model="objCliente.Cruzamiento2" placeholder="Ingrese Cruzamiento2"> </b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Cruzamiento2" :Mensaje="'*' + errorvalidacion.Cruzamiento2[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Colonia">Colonia <span class="text-danger">*</span></label>
                                                        <b-form-input id="Colonia" v-model="objCliente.Colonia" type="text" placeholder="Ingrese Colonia" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*' + errorvalidacion.Colonia[0]"/>
                                                    </div>
                                                </div>

                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Estado">Estado <span class="text-danger">*</span></label>
                                                        <select id="Estado" class="form-control form-select" v-model="objCliente.IdEstado" @change="ListaMunicipios()">
                                                            <option :value="0">--Seleccionar--</option>
                                                            <option v-for="(item, index) in ListaEstadosArray" :key="index" :value="item.IdEstado">
                                                                {{ item.Nombre }}
                                                            </option>
                                                        </select>
                                                        <CValidation v-if="this.errorvalidacion.IdEstado" :Mensaje="'*' + errorvalidacion.IdEstado[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Municipio">Municipio <span class="text-danger">*</span></label>
                                                        <select v-model="objCliente.IdMunicipio" id="Municipio" class="form-control form-select">
                                                            <option :value="0">--Seleccionar--</option>
                                                            <option v-for="(item, index) in ListaMunicipiosArray" :key="index" :value="item.IdMunicipio" >
                                                                {{ item.Nombre }}
                                                            </option>
                                                        </select>
                                                        <CValidation v-if="this.errorvalidacion.IdMunicipio" :Mensaje="'*' + errorvalidacion.IdMunicipio[0]"/>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                        <label for="Referencias">Referencias</label>
                                                        <b-form-textarea id="Referencias" v-model="objCliente.Referencias" placeholder="Ingrese Referencias" ></b-form-textarea>
                                                        <CValidation v-if="this.errorvalidacion.Referencias" :Mensaje="'*' + errorvalidacion.Referencias[0]"/>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset class="mt-2">
                                                <legend class="col-form-label">&nbsp;Ubicación Corporativo&nbsp;</legend>

                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <CMapa v-if="this.isReady" :pConfigMapa="ConfigMapa" :oLatLng="coordenadas" />
                                                    </div>
                                                </div>

                                            </fieldset>


                                        </div>
                                    </div>
                                    <CBtnSave :poBtnSave="oBtnSave" />

                                </div>
                            </div>

                            <!--Parte Dos SUCURSALES -->
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div class="container-fluid">
                                    <fieldset>

                                        <legend class="col-form-label">&nbsp;Sucursales&nbsp;</legend>
                                        <div class="row">
                                            <div class="col-md-12 jutify-content-end">
                                                <button type="button" @click="newSucursal()" class="btn btn-primary btn-sm float-right mt-1 mb-3">
                                                    <i class="fas fa-plus-circle"></i> Nueva Sucursal
                                                </button>
                                            </div>
                                        </div>
                                        <CTablita :pConfigList="ConfigList3">
                                            <template slot="header">
                                                <th>Sucursal</th>
                                                <th>Teléfono</th>
                                                <th>Dirección</th>
                                                <th>Suc. Matriz</th>

                                                <th class="text-center">Acciones</th>
                                            </template>
                                            <template slot="body">
                                                <tr  v-for="(item,index) in ListaClienteSucursales"  :key="index">

                                                    <td>{{ item.Nombre }}</td>
                                                    <td>{{ item.Telefono }}</td>
                                                    <td>{{ item.Direccion }}</td>
                                                    <td v-if="parseInt(item.EsMatriz) === 1"> <b-badge variant="success">Matriz</b-badge></td>
                                                    <td v-if="parseInt(item.EsMatriz) === 0"> <b-badge variant="secondary">Normal</b-badge></td>


                                                    <td class="text-center">
                                                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="parseInt(item.EsMatriz) == 0" :pIsModal="true" :pId="item.IdClienteSucursal" :pEmitSeccion="ConfigList3.EmitSeccion" :segurity="segurity">
                                                            <template slot="btnaccion">
                                                            </template>
                                                        </CBtnAccion>
                                                    </td>

                                                </tr>
                                                <CSinRegistros :pContIF="ListaClienteSucursales.length" :pColspan="7" />
                                            </template>
                                        </CTablita>
                                    </fieldset>
                                </div>
                            </div>



                            <!--Parte Tres Control Comercial -->

                            <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Configuración Fiscal&nbsp;</legend>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                                                        
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3"></div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3"></div>
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3"></div>
                                                </div>

                                            </fieldset>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 mt-3">

                                        <!--    <fieldset>
                                                <legend class="col-form-label">&nbsp;Catalogo de Productos&nbsp;</legend>
                                                <div class="row">
                                                    <div class="col-md-12 jutify-content-end">
                                                        <button type="button" class="btn btn-primary btn-sm float-right">
                                                            <i class="fas fa-plus-circle"></i> Agregar Producto
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="row mt-3">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <table class="table table-responsive-lg">
                                                            <thead>
                                                            <tr>
                                                                <th class="td-sm"></th>
                                                                <th>Producto</th>
                                                                <th>Precio Cliente</th>
                                                                <th>Comision Unitaria</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(producto,indx) in ListaProductoxCliente" :key="indx">
                                                                <td>{{(indx + 1)}}</td>
                                                                <td>
                                                                    <textarea v-model="producto.Descripcion"
                                                                              class="form-control form-control-sm"
                                                                              rows="2" readonly>
                                                               </textarea>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                            v-model="producto.PrecioCliente"
                                                                            type="number"
                                                                            class="form-control form-control-sm"
                                                                            style="width:120px" >
                                                                </td>
                                                                <td>
                                                                    <input
                                                                            v-model="producto.ComisionUnitaria"
                                                                            type="number"
                                                                            class="form-control form-control-sm"
                                                                            style="width:120px" >
                                                                </td>

                                                                <td>
                                                                    <button  type="button" v-b-tooltip.hover.Top title="Eliminar" class="btn btn-danger btn-icon mr-1" >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <CSinRegistros :pContIF="ListaProductoxCliente.length" :pColspan="6" />

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                            </fieldset> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </template>
                </CLoader>
            </template>
        </CList>


        <CModal :pConfigModal="ConfigModalClienteSucusales" :poBtnSave="poBtnSaveComentCliente">
            <template slot="Form">
                <ClienteSucursales :poBtnSave="poBtnSaveComentCliente" />
            </template>
        </CModal>
    </div>
</template>

<script>

    import CBtnSave      from "@/components/CBtnSave";
    import Configs       from "@/helpers/VarConfig.js";
    import CValidation   from "@/components/CValidation.vue"
    import CSinRegistros from "@/components/CSinRegistros";
    import CBtnAccion    from "@/components/CBtnAccion";
    import CLoader       from "../../../../components/CLoader";
    import CList         from "../../../../components/CList";
    import CModal        from "../../../../components/CModal";
    import moment        from "moment";
    import ClienteSucursales from "./FormClienteSucursal.vue";
    import CMapa from "@/components/CMapa.vue";

    const EmitEjecuta    = "seccionClienteActivo";
    const EmitEjecuta2   = "seccionClienteActivo2";
    const EmitEjecuta3   = "seccionSucursales";


    export default {
        name:  "DetallesCliente",
        props: ["Id"],
        components: {
            CBtnSave,
            CValidation,
            CSinRegistros,
            CBtnAccion,
            CLoader,
            CList,
            CModal,
            ClienteSucursales,
            CMapa
        },
        data() {
            return {
                RutaEviSol:                 "",
                NombreRuta:                 "",
                Sucursales:                 "",
                NombreCompleto:             "",
                Img:                        null,
                RutaFile:                   "",
                RutaEvid:                   '',
                MontoSeleccionado:          0,
                isReady:                    false,
                getNuevoPrestamo:           false,
                ValidElement:               Configs.validImage2m,
                ListaEviSolicitud:          [],
                IneArrayRows:               [],
                LocalArrayRows:             [],
                errorvalidacion:            [],
                ListaRutasArray:            [],
                ListaEstadosArray:          [],
                DomilicioArrayRows:         [],
                ListaMunicipiosArray:       [],
                ListaSucursalesArray:       [],
                ListaRutasEmpleadosArray:   [],
                ListaMontosPrestamosArray:  [],
                ListaINEArray:              [],
                ListaDomicilioArray:        [],
                ListaEmpresaArray:          [],
                ListaClienteSucursales:       [],
                ListaRuta:                  [],
                Negocio:                    [],
                listComentariosPrestamo:    [],
                Prestamo:{
                    evidencia_solicitud:[],
                },
                segurity:                   {
                    Edit: true,
                    Delete: true
                },
                ConfigList: {
                    ShowTitleFirst:    false,
                    Title:             "Detalles Cliente",
                    ShowLoader:        true,
                    IsModal:           false,
                    BtnNewShow:        false,
                    BtnReturnShow:     true,
                    TypeBody:          "Form",
                    ShowFiltros:       false,
                    ShowFiltrosInline: true,
                    ShowTitleInline:   false,
                    ShowPaginador:     false,
                },
                oBtnSave: {
                    toast:         0,
                    IsModal:       false,
                    ShowBtnSave:   true,
                    ShowBtnCancel: false,
                    DisableBtn:    false,
                    EmitSeccion:   EmitEjecuta,
                },
                ConfigLoad: {
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                ConfigLoad2: {
                    ShowLoader: false,
                    ClassLoad:  false,
                },
                objCliente: {
                    IdCliente: 	 		0,

                    NombreComercial: 	"",
                    RazonSocial: 	 	"",
                    Rfc: 	 	        "",

                    NumCliente: 		"",
                    TipoNegocio: 		0,
					Negocio: 			[],
                    Contacto: 			"",
                    Telefono: 		 	"",
                    Correo: 		 	"",

                    Direccion:          "", // eliminar
					Calle: 	 	 		"",
                    NoExt: 	 	 		"",
					NoInt: 	 	 		"",
					Cp: 	 	 		"",
					Cruzamiento1:	 	"",
					Cruzamiento2:		"",
                    Colonia: 	 		"",
                    IdEstado: 	 		0,
                    IdMunicipio: 		0,
                    Referencias: 		"",

					Latitud:  			0,
					Longitud: 			0,
					CompraConIva: 	    true,
					Imagen: 	 		"",
                },
                masks: {
                    input: 'YYYY-MM-DD',
                },
                Filtro: {
                    Nombre:         "",
                    Pagina:         1,
                    Entrada:        25,
                    TotalItem:      0,
                    Placeholder:    "Buscar..",
                },



                ConfigMapa:{
                    ShowBuscador: true
                },
                coordenadas: {
                    Lat: 20.9673271,
                    Lng: -89.6249853,
                },

                ConfigModalClienteSucusales: {
                    ModalTitle:  "Sucursales",
                    ModalNameId: 'ModalForm',
                    EmitSeccion: EmitEjecuta3,
                    ModalSize:   'xl',
                    ShowFooter: true,
                },
                poBtnSaveComentCliente:{
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta3,
                    ShowBtnSave: true,
                    IdObjeto: 0,
                },

                ConfigList3: {
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'List',
                    ShowTitleFirst: false,
                    EmitSeccion:    EmitEjecuta3,

                },

                ConfigListComentarios: {
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'List',
                    ShowTitleFirst: false,
                    EmitSeccion:    EmitEjecuta2,
                },


                // COMERCIAL
                ListaProductoxCliente:[
                    {
                        IdProductoxCliente:0,
                        IdProducto: 1,
                        IdVendedor: 3,
                        Descripcion: 'BOLSA DE CARBON VEGETAL 3KG',
                        PrecioCliente: 38.20,
                        ComisionUnitaria: 2.50

                    },
                    {
                        IdProductoxCliente:0,
                        IdProducto: 1,
                        IdVendedor: 3,
                        Descripcion: 'ATADO DE LEÑA',
                        PrecioCliente: 40.00,
                        ComisionUnitaria: 3.50

                    }
                ],
            };
        },
        methods: {
            async Guardar() {
                this.errorvalidacion     = [];
                this.oBtnSave.toast      = 0;
                this.oBtnSave.DisableBtn = true;

                // CLIENTE
                let formData = new FormData();
                formData.set("Origen", "web");
                formData.set("IdCliente",           this.objCliente.IdCliente);
                formData.set("NombreComercial", 	this.objCliente.NombreComercial);
                formData.set("RazonSocial", 		this.objCliente.RazonSocial);
                formData.set("Rfc", 		        this.objCliente.Rfc);

                formData.set("TipoNegocio", 		this.objCliente.TipoNegocio);
                formData.set("Negocio", 			this.objCliente.Negocio);
                formData.set("Contacto", 			this.objCliente.Contacto);
                formData.set("Telefono", 		    this.objCliente.Telefono);
                formData.set("Correo", 	            this.objCliente.Correo);

                formData.set("Calle", 			    this.objCliente.Calle);
                formData.set("NoExt", 			    this.objCliente.NoExt);
                formData.set("NoInt", 			    this.objCliente.NoInt);
                formData.set("Cp", 				    this.objCliente.Cp);
                formData.set("Cruzamiento1", 		this.objCliente.Cruzamiento1);
                formData.set("Cruzamiento2", 		this.objCliente.Cruzamiento2);
                formData.set("Colonia", 			this.objCliente.Colonia);
                formData.set("IdEstado", 			this.objCliente.IdEstado);
                formData.set("IdMunicipio", 		this.objCliente.IdMunicipio);
                formData.set("Referencias", 		this.objCliente.Referencias);

                formData.set("Latitud", 			this.objCliente.Latitud);
                formData.set("Longitud", 			this.objCliente.Longitud);
                formData.set("CompraConIva", 		(this.objCliente.CompraConIva == true) ? 1 : 0);

                formData.set("ImagenPrevious", 	this.objCliente.Imagen);

                let Imagen = this.$refs.file.files[0];
                formData.append("Imagen", Imagen);

                await this.$http.post("clientes", formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    this.EjecutaConExito(res);
                    this.objCliente = res.data.data.cliente;
                })
                .catch((err) => {
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.oBtnSave.DisableBtn = false;
                this.oBtnSave.toast      = 1;
                this.Regresar();
            },
            EjecutaConError(err) {
                this.oBtnSave.DisableBtn = false;

                if (err.response.data.errors) {
                    this.errorvalidacion = err.response.data.errors;
                    this.oBtnSave.toast  = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }

            },
            Recuperar() {
                this.$http.get("clientes/"+this.objCliente.IdCliente)
                    .then((res) => {
                        this.objCliente                 = res.data.data.Cliente;
                        this.RutaFile                   = res.data.RutaFile;
                        this.objCliente.CompraConIva    = parseInt(this.objCliente.CompraConIva) > 0 ? true : false;

                        this.ListaMunicipios(this.objCliente.IdEstado);
                        this.setDefaultorCurrentLocation();
                        this.isReady = true;

                    }).finally(() => {
                        this.ConfigLoad.ShowLoader = false;
                    });
            },


            setDefaultorCurrentLocation() {
                this.coordenadas.Lat = (this.objCliente.Latitud != null || this.objCliente.Latitud != undefined) ? parseFloat(this.objCliente.Latitud) : 20.9673271;
                this.coordenadas.Lng = (this.objCliente.Longitud != null || this.objCliente.Longitud != undefined) ? parseFloat(this.objCliente.Longitud) : -89.6249853;
            },



            Limpiar() {
                this.objCliente = {
                    IdCliente: 	 		0,
					IdEstado: 	 		0,
					IdMunicipio: 		0,
					Nombre: 	 	 	"",
					ApellidoPat: 	 	"",
					ApellidoMat: 	 	"",
					FechaNacimiento: 	"",
					Correo: 		 	"",
					Telefono: 		 	"",
					NombreNegocio: 		"",
					Negocio: 			[],
					TipoNegocio: 		0,
					DescripcionNegocio: "",
					Calle: 	 	 		"",
					NoInt: 	 	 		"",
					NoExt: 	 	 		"",
					Colonia: 	 		"",
					Curp: 	 	 		"",
					Cp: 	 	 		"",
					Cruzamiento1: 	 	"",
					Cruzamiento2: 	 	"",
					Referencias: 		"",
					Prospecto: 	 		"",
					Estatus: 	 		"",
					Latitud:  			0,
					Longitud: 			0,
					CompraConIva: 		true,
					Imagen: 	 		"",
                };
                this.listComentariosPrestamo = [];
            },

            Regresar() {
                this.$router.push({name:'clientesActivos',params:{}});
            },

            async ListaMunicipios(id) {
                if (typeof id != "undefined") {
                    this.objCliente.IdEstado = id;
                }
                await this.$http
                    .get("municipios", {
                        params: {
                            IdEstado: this.objCliente.IdEstado,
                        },
                    })
                    .then((res) => {
                        if (typeof id == "undefined") {
                            this.ListaMunicipiosArray   = []
                            this.objCliente.IdMunicipio = 0;
                        }
                        this.ListaMunicipiosArray = res.data.data;
                    });
            },

            /********************** SUCURSALES ***************************/
            RecuperarSucursales(){
                this.ConfigList3.ShowLoader =  true;
                this.ListaClienteSucursales = [];

                this.$http.get("sucursalcliente", {
                    params: {
                        IdCliente: this.objCliente.IdCliente,
                        Entrada:100,
                        Pag:1
                    }
                }).then((res) => {
                    this.ListaClienteSucursales   = res.data.data.data;
                    this.ConfigList3.ShowLoader =  false;

                }).finally(() => {
                    this.ConfigList3.ShowLoader =  false;
                    this.ConfigLoad.ShowLoader  = false;
                });
            },

            newSucursal() {
                this.poBtnSaveComentCliente.IdObjeto = this.objCliente.IdCliente;
                this.bus.$emit('NewModal_'+EmitEjecuta3);
            },

            EliminarSucursal(Id){
                this.$swal(Configs.configEliminar).then((result) => {
                    if(result.value) {
                        this.$http.delete('sucursalcliente/'+Id).then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.RecuperarSucursales();

                        }).catch( err => {
                                this.$toast.error(err.response.data.message);
                            });
                    }
                });
            },

           /* formato(fecha){
                let formato = moment(fecha).format('DD-MM-YYYY');

                if(fecha!=null){
                    return formato;
                }

            },

            listaComentarios() {
                this.$http.get("clientecomentarios",{
                    params:{
                        IdCliente: this.objCliente.IdCliente,
                    }
                }).then((res) => {
                    this.listComentariosPrestamo = res.data.data;
                });
            },

            */
			ListaNegocio() {
				this.$http.get("negocios").then((res) => {
					this.Negocio = res.data.data.map( item =>({
						id:    item.IdTipoNegocio,
						label: item.Negocio
					}));
					// this.Negocio.push({ id: 0, label: "Seleccione un Tipo de Negocio"});
				});
			},

            previewCliente() {
                window.open(this.RutaFile+this.objCliente.UrlImg,'Nueva Ventana',"width=600,height=800");
            },
        },
        created() {
            this.bus.$off("Save_"+EmitEjecuta);
            this.bus.$off("EmitReturn");
            this.bus.$off("List_" + EmitEjecuta2);

            this.bus.$off("List_" + EmitEjecuta3);
            this.bus.$off('Delete_'+EmitEjecuta3);

            this.Limpiar();

            if(this.Id !== undefined){
                sessionStorage.setItem('IdClienteR',this.Id);
            }

            this.objCliente.IdCliente = sessionStorage.getItem('IdClienteR');
            this.ListaEstadosArray = JSON.parse(sessionStorage.getItem("ListaEstadosArray"));
            this.oBtnSave.toast    = 0;
        },
        mounted() {
			this.ListaNegocio();
            this.oBtnSave.DisableBtn = false;

            if (this.objCliente.IdCliente > 0) {
                //this.objCliente.IdCliente = this.Id;
                //sessionStorage.setItem('IdGeneral',this.Id);
                this.Recuperar();
                this.RecuperarSucursales();

            } else {
                this.ConfigLoad.ShowLoader = false;
                this.isReady = true;
            }

            this.bus.$on("Save_"+EmitEjecuta, () => {
                this.Guardar();
            });

            this.bus.$on("EmitReturn", () => {
                this.Regresar();
            });


            /**************** CONTROL SUCURSALES *******************/
            this.bus.$on("List_" + EmitEjecuta3, () => {
                this.RecuperarSucursales();
            });

            this.bus.$on("Delete_" + EmitEjecuta3, (Id) => {
                this.EliminarSucursal(Id)
            });

            /**************** END CONTROL SUCURSALES *******************/


        },
        computed:{
            setLocation: function () {
                this.objCliente.Longitud = this.coordenadas.Lng;
                this.objCliente.Latitud  = this.coordenadas.Lat;
                return this.objCliente;
            }
        }
    };

</script>
<style>
.checkbox-lg .form-check-input{
 top: .8rem;
 scale: 1.4;
 margin-right: 0.7rem;
 }

.checkbox-lg .form-check-label {
 padding-top: 13px;
 }
</style>
