<template>
    <CTabla @FiltrarC="lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
        <template slot="header">
            <th class="td-sm"></th>
            <th>Folio</th>
            <th>Fecha Lev.</th>
            <th>Sucursal</th>
            <th>Total</th>

            <th class="text-center">Acciones</th>
        </template>

        <template slot="body">
            <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                <td class="td-sm"></td>
                <td>{{lista.FolioPedido}}</td>
                <td>{{$getCleanDate(lista.FechaLevantamiento,false)}}</td>

                <td v-if="lista.clientesucursal !== null">{{ $limitCharacters(lista.clientesucursal.Nombre,45) }}</td>
                <td v-else>-----</td>

                <td>{{lista.Total}}</td>



                <td class="text-center">
                    <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                        <template slot="btnaccion">

                            <button v-if="lista.Visible" type="button" v-b-tooltip.hover.Top title="Agregar" class="btn btn-primary btn-icon" @click="asignarElemento(lista)">
                                <i class="fas fa-plus"></i>
                            </button>
                            <span v-else class="badge badge-success badge-pill">Agregado</span>

                        </template>
                    </CBtnAccion>
                </td>
            </tr>
            <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistros>
        </template>
    </CTabla>
</template>
<script>
import CTabla from "@/components/CTabla.vue";
import CSinRegistros from "@/components/CSinRegistros.vue";

const EmitEjecuta = 'seccionSelectPedidosP';

export default {
    name: "ListaSelectPedidosPendiente",
    props: ['poBtnSave','pPedidosAgregados'],
    components: {
        CTabla,
        CSinRegistros
    },

    data() {
        return {
            Emit:               this.poBtnSave.EmitSeccion,
            errorvalidacion:    [],
            ListaArrayRows:     [],
            ListaHeader:        [],
            objEquipos:         {},
            segurity:           {},
            obj:                {},
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            ConfigList:{
                Title: 'Listado Pedidos Pendientes',
                ShowLoader: false,
                BtnNewShow: false,
                ShowTitleFirst: false,
                EmitSeccion:   EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            objCliente:{
                IdCliente:0
            },
            localIdOrdenProduccion:0,
            localPedidosAgregados: []
        }
    },
    methods: {
        async lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('pedidosagregaorden', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                    IdCliente:   this.objCliente.IdCliente,
                    ConDetalle: 1,
                    Estatus:    'Pendiente'
                }
            }).then( (res) => {
                this.ListaArrayRows   = res.data.data.data;
                this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total;

                this.ListaArrayRows.forEach((item, index) => {
                    item.Visible = true


                    let arrDetalle = this.localPedidosAgregados.filter(function(elemento,index){
                        if(item.IdPedido == elemento.IdPedido){
                            return item;
                        } else {
                            return '';
                        }
                    });

                    if(arrDetalle[0]){
                        item.Visible = false;
                    }

                });

            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },

        asignarElemento(item) {

            this.bus.$emit('pAsignarPedidoPen',item);
            this.bus.$emit('CloseModal_'+this.Emit);
        },
    },
    computed: {},

    created() {
        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            this.objCliente.IdCliente = obj.IdCliente;
            this.localIdOrdenProduccion = obj.IdOrdenProduccion;
            this.lista();
        });
    },mounted() {

        if(this.pPedidosAgregados !== undefined){
            this.localPedidosAgregados =  this.pPedidosAgregados;
        }

    }

}
</script>
