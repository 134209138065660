<template>
    <div class="form-group">
        <label>Proveedor</label>
        <input :value="checkId" type="hidden">
        <v-select
                :value="localValue"
                @input="$emit('input', $event)"
                @search="onSearchClientes"
                :filterable="false"
                :reduce="item => item.key"
                :options="proveedores"
                placeholder="Buscar proveedor">

            <template slot="no-options">
                No hay proveedores
            </template>

            <template slot="option" slot-scope="option">
                <div class="row">
                    <div class="col-2 col-sm-2 col-md-1 col-lg-2 col-xl-1">
                        <i class="fa fa-user" ></i>
                    </div>
                    <div class="col-10 col-sm-10 col-md-11 col-lg-10 col-xl-8">
                        {{ option.label }}
                        <div class="row">
                            <div class="col-12"  >
                                {{ option.detalle.direccion }}
                            </div>
                        </div>
                    </div>

                </div>
            </template>

        </v-select>

        <slot></slot>

    </div>
</template>

<script>
export default {

    props: [
        'value'
    ],

    data() {
        return {
            localValue: 0,
            //form: this.value,

            proveedores: [],
            debounce: null


        }
    },

    methods: {

        onSearchClientes( search, loading ) {

            this.proveedores = [];

            clearTimeout( this.debounce );

            loading(true);

            this.debounce = setTimeout( async () => {

                await this.getClientes( search, loading );

            }, 1000);

        },

        async getClientes( search, loading ) {

            const res = await this.$http.get( '/proveedores', {
                params: {
                    TxtBusqueda: search,
                    items: 10,
                    page: 1
                }
            });

            loading(false);

            if ( res.status != 200 ) {

                this.clientes = [];

            };


            this.proveedores = res.data.data.data.map( (item) => ({
                label: `${item.Nombre}`,
                detalle: {
                    direccion: item.Direccion,
                },
                key: item.IdProveedor
            }) );

        },

    },
    computed:{
        checkId: function (){
            this.localValue = parseInt(this.value);
        }
    }

}
</script>

<style>

</style>
