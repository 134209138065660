<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="form-group row">
                        <div :class="(EsCobratario)?'col-12 col-sm-12 col-md-9 col-lg-9':'col-12 col-sm-12 col-md-12 col-lg-12'">
                            <label>Empleado:</label>
                            <span><strong> {{ObjUser.NombreCompleto}}</strong></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div :class="(EsCobratario)?'col-12 col-sm-12 col-md-4 col-lg-4':'col-12 col-sm-12 col-md-12 col-lg-12'">
                            <label for="Nombre">Fecha Baja</label>
                            <v-date-picker :masks="masks" :popover="{ visibility: 'focus'}" locale="es" v-model="objBaja.FechaBaja" :maxDate="new Date()">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control cal" name="FechaEntrega[]" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly/>
                                </template>
                            </v-date-picker>
                            <CValidation v-if="this.errorvalidacion.FechaBaja" :Mensaje="'*'+errorvalidacion.FechaBaja[0]"/>
                        </div><!--fin col-12-->
                    </div>
                    
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import moment from "moment"

export default {
    name:"FormBaja",
    props:['poBtnSave'],
    components:{
    },
    data() {
        return {
            Emit: this.poBtnSave.EmitSeccion,
            EsCobratario: false,
            ListUsersArr:    [],
            ListaPrestamos:  [],
            ListaAsignados:  [],
            errorvalidacion: [],
            ObjUser:         {},
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objBaja:{
                IdUsuario: 0,
                FechaBaja: '',
                IdCobratario: 0,
                IdPerfil: 0,
                EstatusPrestamo: 'Cobranza'
            },
            masks: {
                input: "YYYY-MM-DD",
            },
        }
    },
    methods :
    {
        async Guardar()
        {
            let Fecha1 = '';
			if(this.objBaja.FechaBaja!=''){
                Fecha1 = moment(this.objBaja.FechaBaja).format('YYYY-MM-DD');
            }

            this.objBaja.FechaBaja = Fecha1;

            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0; 
            this.poBtnSave.DisableBtn = true;

            this.$http.post('ReasignarPrestamosUsuario',this.objBaja).then((res)=>{                 
                this.EjecutaConExito(res);
            }).catch(err=>{   
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast      = 1;

            if(res.data.data == 1)
            {
                this.objBaja.IdCobratario = 0;
                this.ListaAsignados = [];
                this.Recuperar();
            }
            else
            {
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_seccionEmpleadosList');
            }
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
        Limpiar()
        {
            this.objBaja = {
                IdUsuario:    0,
                FechaBaja:    '',
                IdCobratario: 0,
                IdPerfil:     0,
            };
            this.errorvalidacion = [];
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=> {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);

            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });

            this.Limpiar();
            this.ObjUser = obj;
            
            if(obj.IdUsuario!='')  {
                this.objBaja.IdUsuario = obj.IdUsuario;
                this.objBaja.IdPerfil  = obj.IdPerfil;

                this.ConfigLoad.ShowLoader = false;
            } else {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },
}
</script>